import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";

const LayoutAr: React.FunctionComponent<{}> = (props) => {

    return (
          	<div id="wrapper" className="style-ar">
                  <Header />
                  <main id="main">
                  <Outlet />
                  </main>
                  <Footer/>
            </div>
    )
}

export default LayoutAr