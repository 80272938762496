import React, { useState } from "react";

const CommonQuestions: React.FunctionComponent<{}> = (props) => {

 const [isShowQues1, setIsShowQues1]=useState<Boolean>(true);
 const [isShowQues2, setIsShowQues2]=useState<Boolean>(true);
 const [isShowQues3, setIsShowQues3]=useState<Boolean>(true);
 const [isShowQues4, setIsShowQues4]=useState<Boolean>(true);
 const [isShowQues5, setIsShowQues5]=useState<Boolean>(true);
 const [isShowQues6, setIsShowQues6]=useState<Boolean>(true);


    return (
        <div className="service-sec service-common">
            <div className="container">
                <div className="row">
                    <div className=" col-md-4">
                        <header className=" text-center header">
                            <h2 className="service-common-h2">FAQs</h2>
                            <h3 className="main-heading2 text-align-start">Common questions</h3>
                        </header>
                    </div>
                    <div className=" col-md-8">
                        <div className="card-common">
                            <div className="question-gras">
                                What is virtual staging?
                                {isShowQues1 ? <span  className="pointer-right"  onClick={()=>setIsShowQues1(!isShowQues1)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                :<span className="pointer-right" onClick={()=>setIsShowQues1(!isShowQues1)}>
                                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                </svg>
                                </span>}
                            </div>
                           {isShowQues1 ==false && <p className="question" style={{ fontWeight: "400", marginTop: "5px", lineHeight: "28px", opacity: "0.7", fontSize: "16px" }}>
                                Virtual staging is a digital technique used to enhance and visualize real estate spaces by adding furniture, decor, and other elements to photographs, creating an appealing and realistic representation of a property.
                            </p>}
                        </div>
                        <div className="card-common">
                            <div className="question-gras">
                                Why should I pick virtual staging over physical staging?
                                {isShowQues2 ? <span  className="pointer-right"  onClick={()=>setIsShowQues2(!isShowQues2)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                :<span className="pointer-right" onClick={()=>setIsShowQues2(!isShowQues2)}>
                                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                </svg>
                                </span>}
                            </div>
                            {isShowQues2 ==false && <p className="question" style={{ fontWeight: "400", marginTop: "5px", lineHeight: "28px", opacity: "0.7", fontSize: "16px" }}>
                                Virtual staging offers cost savings, flexibility, and speed compared to physical staging. It allows for quick adjustments, is more budget-friendly, and eliminates the need for physical furniture, making it ideal for online marketing and faster property turnover.
                            </p>}
                        </div>


                        <div className="card-common">
                            <div className="question-gras">
                                What kind of photos do you support?
                                {isShowQues3 ? <span  className="pointer-right"  onClick={()=>setIsShowQues3(!isShowQues3)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                :<span className="pointer-right" onClick={()=>setIsShowQues3(!isShowQues3)}>
                                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                </svg>
                                </span>}
                            </div>
                            {isShowQues3 ==false &&   <p className="question" style={{ fontWeight: "400", marginTop: "5px", lineHeight: "28px", opacity: "0.7", fontSize: "16px" }}>
                                Our AI virtual staging supports a variety of real estate photos, including empty rooms or spaces with existing furniture. High-quality, well-lit images with clear room details yield the best results.
                            </p>}
                        </div>
                        <div className="card-common">
                            <div className="question-gras">
                                How long does it take to virtually stage by Al?
                                {isShowQues4 ? <span  className="pointer-right"  onClick={()=>setIsShowQues4(!isShowQues4)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                :<span className="pointer-right" onClick={()=>setIsShowQues4(!isShowQues4)}>
                                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                </svg>
                                </span>}
                            </div>
                            {isShowQues4 ==false &&  <p className="question" style={{ fontWeight: "400", marginTop: "5px", lineHeight: "28px", opacity: "0.7", fontSize: "16px" }}>
                                The time it takes for AI virtual staging depends on factors such as image complexity and resolution. Generally, the process is quick, less that 8 seconds .
                            </p>}
                        </div>
                        <div className="card-common">
                            <div className="question-gras">
                                How big is your furniture library?
                                {isShowQues5 ? <span  className="pointer-right"  onClick={()=>setIsShowQues5(!isShowQues5)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                :<span className="pointer-right" onClick={()=>setIsShowQues5(!isShowQues5)}>
                                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                </svg>
                                </span>}
                            </div>
                            {isShowQues5==false &&   <p className="question" style={{ fontWeight: "400", marginTop: "5px", lineHeight: "28px", opacity: "0.7", fontSize: "16px" }}>
                                Our AI virtual staging doesn't rely on a predefined furniture library. Instead, it analyzes each room individually and generates furniture based on the room's style and type. This ensures a unique and tailored virtual staging experience for each room, with no repetition of furniture across different spaces.
                            </p>}
                        </div>
                        <div className="card-common">
                            <div className="question-gras">
                                I purchased a plan but can't access it. What should I do?
                                {isShowQues6 ? <span  className="pointer-right"  onClick={()=>setIsShowQues6(!isShowQues6)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                :<span className="pointer-right" onClick={()=>setIsShowQues6(!isShowQues6)}>
                                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                </svg>
                                </span>}
                            </div>

                            {isShowQues6 ==false && <p className="question" style={{ fontWeight: "400", marginTop: "5px", lineHeight: "28px", opacity: "0.7", fontSize: "16px" }}>
                                If you're experiencing issues accessing your purchased plan, please contact our customer support. They will assist you in resolving the issue and ensuring
                            </p>}
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
export default CommonQuestions;