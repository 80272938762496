import React from 'react';

const DesignsTotal: React.FunctionComponent<{}> = (props) => {

      return (
        <div className="service-sec service2 ">
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <div className="service text-center">
                        <div className="img">
                            <svg width="81" height="80" viewBox="0 0 81 80" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4"
                                    d="M73.8333 55V65C73.8333 70 70.5 73.3333 65.5 73.3333H20.5C21.8667 73.3333 23.2667 73.1333 24.5667 72.7C24.9333 72.5666 25.3 72.4333 25.6666 72.2666C26.8333 71.8 27.9667 71.1333 28.9667 70.2666C29.2667 70.0333 29.6 69.7333 29.9 69.4333L30.0333 69.3L52.7 46.6666H65.5C70.5 46.6666 73.8333 50 73.8333 55Z"
                                    fill="#2EBB84" />
                                <path opacity="0.6"
                                    d="M61.7333 37.6334L52.7 46.6667L30.0333 69.3C32.3666 66.9 33.8333 63.6 33.8333 60V27.8L42.8666 18.7667C46.4 15.2334 51.1333 15.2334 54.6667 18.7667L61.7333 25.8334C65.2666 29.3667 65.2666 34.1 61.7333 37.6334Z"
                                    fill="#2EBB84" />
                                <path
                                    d="M25.5 6.66663H15.5C10.5 6.66663 7.16667 9.99996 7.16667 15V60C7.16667 60.9 7.26663 61.8 7.43329 62.6666C7.53329 63.1 7.63331 63.5333 7.76665 63.9666C7.93331 64.4666 8.09999 64.9666 8.29999 65.4333C8.33333 65.4666 8.33336 65.5 8.33336 65.5C8.36669 65.5 8.36669 65.5 8.33336 65.5333C8.80003 66.4666 9.33333 67.3666 9.96666 68.2C10.3333 68.6333 10.7001 69.0333 11.0667 69.4333C11.4334 69.8333 11.8333 70.1666 12.2667 70.5L12.3 70.5333C13.1334 71.1666 14.0333 71.7 14.9667 72.1666C15 72.1333 15 72.1333 15 72.1666C15.5 72.4 16 72.5666 16.5333 72.7333C16.9667 72.8666 17.4 72.9666 17.8334 73.0666C18.7 73.2333 19.6 73.3333 20.5 73.3333C21.8667 73.3333 23.2667 73.1333 24.5667 72.7C24.9333 72.5666 25.3 72.4333 25.6666 72.2666C26.8333 71.8 27.9667 71.1333 28.9667 70.2666C29.2667 70.0333 29.6 69.7333 29.9 69.4333L30.0333 69.3C32.3666 66.9 33.8333 63.6 33.8333 60V15C33.8333 9.99996 30.5 6.66663 25.5 6.66663ZM20.5 65C17.7333 65 15.5 62.7666 15.5 60C15.5 57.2333 17.7333 55 20.5 55C23.2667 55 25.5 57.2333 25.5 60C25.5 62.7666 23.2667 65 20.5 65Z"
                                    fill="#2EBB84" />
                            </svg>
                        </div>
                        <h2 dir="auto" className="mb-2">50+ تصميمًا</h2>
                        <p dir="auto">أكثر من 50 نمط فريد للاختيار من بينها.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="service text-center">
                        <div className="img ">
                            <svg width="81" height="80" viewBox="0 0 81 80" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4"
                                    d="M63.8334 30C63.8334 34.8333 62.4 39.2667 59.9334 42.9667C56.3334 48.3 50.6334 52.0666 44 53.0333C42.8667 53.2333 41.7 53.3333 40.5 53.3333C39.3 53.3333 38.1334 53.2333 37 53.0333C30.3667 52.0666 24.6667 48.3 21.0667 42.9667C18.6 39.2667 17.1667 34.8333 17.1667 30C17.1667 17.1 27.6 6.66663 40.5 6.66663C53.4 6.66663 63.8334 17.1 63.8334 30Z"
                                    fill="#2EBB84" />
                                <path
                                    d="M71.3333 61.5667L65.8333 62.8666C64.6 63.1666 63.6333 64.1 63.3666 65.3334L62.2 70.2334C61.5666 72.9 58.1666 73.7001 56.4 71.6001L40.5 53.3334L24.6 71.6333C22.8333 73.7333 19.4333 72.9333 18.8 70.2666L17.6333 65.3666C17.3333 64.1333 16.3666 63.1667 15.1666 62.9L9.66664 61.6001C7.13331 61.0001 6.23331 57.8333 8.06664 56L21.0666 43C24.6666 48.3333 30.3666 52.1001 37 53.0667C38.1333 53.2667 39.3 53.3667 40.5 53.3667C41.7 53.3667 42.8666 53.2667 44 53.0667C50.6333 52.1001 56.3333 48.3333 59.9333 43L72.9333 56C74.7666 57.8 73.8666 60.9667 71.3333 61.5667Z"
                                    fill="#2EBB84" />
                                <path
                                    d="M42.4333 19.9334L44.4 23.8667C44.6667 24.4 45.3667 24.9334 46 25.0334L49.5667 25.6333C51.8333 26 52.3667 27.6667 50.7333 29.3L47.9667 32.0667C47.5 32.5333 47.2333 33.4334 47.4 34.1L48.2 37.5334C48.8333 40.2334 47.4 41.3 45 39.8666L41.6667 37.9C41.0667 37.5333 40.0667 37.5333 39.4667 37.9L36.1333 39.8666C33.7333 41.2666 32.3 40.2334 32.9333 37.5334L33.7333 34.1C33.8667 33.4667 33.6333 32.5333 33.1667 32.0667L30.4 29.3C28.7667 27.6667 29.3 26.0333 31.5667 25.6333L35.1333 25.0334C35.7333 24.9334 36.4333 24.4 36.7 23.8667L38.6667 19.9334C39.6333 17.8 41.3667 17.8 42.4333 19.9334Z"
                                    fill="#2EBB84" />
                            </svg>
                        </div>
                        <h2 dir="auto" className="mb-2">توفير المال والوقت</h2>
                        <p dir="auto">يقوم المستخدمون الخاصون والشركات بخلق قيمة وتوفير التكاليف يوميًا.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="service text-center">
                        <div className="img">
                            <svg width="81" height="80" viewBox="0 0 81 80" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M58.2 37.5C58.2667 38.3667 57.9667 39.2666 57.3 39.9333L37.2333 60C32.8 64.4333 28.3333 64.4333 23.8667 60L10.5 46.6333C8.23333 44.3333 7.09999 42.0333 7.16666 39.7333H7.39999L57.8 37.5334L58.2 37.5Z"
                                    fill="#2EBB84" />
                                <path opacity="0.4"
                                    d="M57.3 35.4667L32.8 10.9665L29.9 8.06655C28.9334 7.09989 27.3333 7.09989 26.3667 8.06655C25.4 9.03322 25.4 10.6332 26.3667 11.5999L29.2667 14.4999L10.5 33.2665C8.36669 35.3999 7.23335 37.5666 7.16669 39.7332H7.40002L57.8 37.5333L58.2 37.4999C58.1667 36.7666 57.8334 36 57.3 35.4667Z"
                                    fill="#2EBB84" />
                                <path
                                    d="M53.8333 75.8334H10.5C9.13333 75.8334 8 74.7 8 73.3334C8 71.9667 9.13333 70.8334 10.5 70.8334H53.8333C55.2 70.8334 56.3333 71.9667 56.3333 73.3334C56.3333 74.7 55.2 75.8334 53.8333 75.8334Z"
                                    fill="#2EBB84" />
                                <path
                                    d="M65 49.2667C64.1333 48.3333 62.5333 48.3333 61.6667 49.2667C60.6333 50.4 55.5 56.1998 55.5 60.5665C55.5 64.8998 59 68.4 63.3333 68.4C67.6667 68.4 71.1667 64.8998 71.1667 60.5665C71.1667 56.1998 66.0333 50.4 65 49.2667Z"
                                    fill="#2EBB84" />
                            </svg>
                        </div>
                        <h2 dir="auto" className="mb-2">محرك التوصيات</h2>
                        <p dir="auto">احصل على توصيات مخصصة بناءً على الأصول التي تم إنشاؤها.</p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="service text-center">
                        <div className="img ">
                            <svg width="81" height="80" viewBox="0 0 81 80" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M58.1333 13.8L57.9 26.4333C57.8666 28.1667 58.9668 30.4667 60.3668 31.5L68.6334 37.7667C73.9334 41.7667 73.0667 46.6667 66.7334 48.6667L55.9668 52.0334C54.1668 52.6 52.2668 54.5667 51.8001 56.4L49.2334 66.2C47.2001 73.9334 42.1333 74.7 37.9333 67.9L32.0666 58.4C31 56.6667 28.4667 55.3667 26.4667 55.4667L15.3334 56.0334C7.36678 56.4334 5.10008 51.8333 10.3001 45.7667L16.9 38.1C18.1333 36.6667 18.7 34 18.1333 32.2L14.7668 21.4333C12.8002 15.1 16.3335 11.6 22.6335 13.6667L32.4668 16.9001C34.1335 17.4334 36.6334 17.0667 38.0334 16.0334L48.3002 8.63335C53.8335 4.63335 58.2667 6.96669 58.1333 13.8Z"
                                    fill="#2EBB84" />
                                <path opacity="0.4"
                                    d="M71.9666 68.2333L61.8665 58.1333C60.8998 57.1667 59.2998 57.1667 58.3332 58.1333C57.3665 59.1 57.3665 60.7 58.3332 61.6667L68.4333 71.7666C68.9333 72.2666 69.5665 72.5 70.1998 72.5C70.8331 72.5 71.4666 72.2666 71.9666 71.7666C72.9332 70.8 72.9332 69.2 71.9666 68.2333Z"
                                    fill="#2EBB84" />
                            </svg>
                        </div>
                        <h2 className="mb-2">نمط جديد كل شهر</h2>
                        <p dir="auto">ابقَ في المقدمة مع أنماط جديدة تُضاف بانتظام.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
      )
}
export default DesignsTotal;