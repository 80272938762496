import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountObj } from "../../Models/Account";
import { CreateAccount } from "../../Services/Authentication";
import InputLabel from '@mui/material/InputLabel';
import { Autocomplete, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";

const SignUp: React.FunctionComponent<{}> = (props) => {

    const [errorMessage, setErrorMessage] = useState<string>();
    const [errors, setErrors] = useState<Partial<AccountObj>>({});
    const [formData, setFormData] = useState<AccountObj>({});
    const [subscription, setSubscription] = useState<any>([{ value: "Basic", type: "Basic Plan" },{ value: "Standard", type: "Standard plan" },{ value: "Enterprise", type: "Enterprise Plan" }]);
    let navigate = useNavigate();

    const handleChangeName = (event: any) => {
        const nameValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            name: nameValue,
        }));
    }

    const handleChangeSubscription = (event: SelectChangeEvent) => {
        const subscription = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            subscription: subscription,
        }));
    };

    const handleChangeEmail = (event: any) => {
        const emailValue = event.target.value;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            email: emailValue,
        }));
    }

    const handleChangePassword = (event: any) => {
        const passwordValue = event.target.value;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            password: passwordValue,
        }));
    }

    const handleCreate = (event: any) => {
        event.preventDefault();
        // Validation email
        if (!formData.email) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Please enter your email address',
            }));
        }
        if (!formData.name) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                name: 'Please enter your name',
            }));
        }
        if (formData.email && !formData.email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Please enter your email in the correct format',
            }));
        }
        if (!formData.subscription) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                subscription: 'Please select Subscription',
            }));
        }
        // Validation password 
        if (!formData.password) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                password: 'Please enter your password',
            }));
        } else {
            CreateAccount(formData).then(res => {
                navigate('/login-en')
            }).catch((error) => {
                setErrorMessage("Faild to create account") 
            })
        }
    }

    return (
        <div className="login-sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <form className="signup-form text-center" onSubmit={handleCreate}>
                            <h2>SIGN UP</h2>
                            {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Name" value={formData.name}
                                    onChange={handleChangeName} />
                                {errors.name && <span className="error">{errors.name}</span>}
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control" placeholder="Email Address" value={formData.email}
                                    onChange={handleChangeEmail} />
                                {errors.email && <span className="error">{errors.email}</span>}
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Password" value={formData.password}
                                    onChange={handleChangePassword} />
                                {errors.password && <span className="error">{errors.password}</span>}
                            </div>
                            <div className="form-group">
                            <FormControl fullWidth sx={{ marginTop: "20px" }}>
									<InputLabel id="demo-simple-select-label" sx={{
										color: "#101410",
										fontSize: "16px",
										fontWeight: "400",
                                        lineHeight: "22px",
										borderRadius: "8px",
										marginTop: '-3px',
										fontFamily: 'Source Sans Pro',
										"&.Mui-focused": {
											color: "#101410",
										},
									}}>Subscription</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={formData.subscription}
										label="Subscription"
                                        className="subsc-left"
										onChange={handleChangeSubscription}
										sx={{
											background: "rgba(255, 255, 255, 0.05)",
											padding: "12px 0px 12px",
											width: "100%",
											color: "#101410",
											fontSize: "16px",
											fontWeight: "400",
											borderRadius: "8px",
											fontFamily: 'Source Sans Pro',
											height: "50px",
											textOverflow: "ellipsis",
											fontStyle: "normal",
											lineHeight: "22px",

											"&:hover": {
												borderColor: "#2EBB84"
											},
											'.MuiOutlinedInput-notchedOutline': {
												borderColor: '#2EBB84',
											},
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: '#2EBB84',
											},
											'&:hover .MuiOutlinedInput-notchedOutline': {
												borderColor: '#2EBB84',
											},
											"&.Mui-focused": {
												borderColor: "#2EBB84",
											},
										}}
									>
										{subscription.map((obj: any, index: any) => {
											return <MenuItem key={index} sx={{
												display: "block",
												width: "100%",
												fontSize: "16px",
												color: "#101410",
												background: "rgba(255, 255, 255, 0.05)",
												fontWeight: "400",
												fontFamily: 'Source Sans Pro',
												height: "50px",
												textOverflow: "ellipsis",
												padding: "12px 12px",
												fontStyle: "normal",
												"&:focus": {
													backgroundColor: "#2EBB84",
													color: "#FFF"
												},
												"&:hover": {
													backgroundColor: "#2EBB84",
													color: "#FFF"
												},
												lineHeight: "22px",
											}} value={obj.value}>{obj.type} </MenuItem>
										})}
									</Select>
							</FormControl>
                            
                            {errors.subscription && <span className="error">{errors.subscription}</span>}
                            </div>
                            <div style={{ marginTop: "50px" }}>
                                <button type="submit" className="btn text-center text-uppercase">SIGN UP</button>
                            </div>
                            <div className="sign-a">
                                <span>Have an account ? <a href="/login-en">LOGIN</a></span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SignUp;