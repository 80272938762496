import React from 'react';

const Services: React.FunctionComponent<{}> = (props) => {

    return (
        <div className="slider9  main--slider "  id="services" style={{background: "#08261B"}}>
				<div className="container">
					<div className="slide">
						<div className="caption text-center sec-9-title">
							<span className="button-title" >WHO CAN BENEFIT from Nesmah?</span>
							<h1 className="white-color">Nesmah is Your Time and Money Saver for both Personal and
								Business Needs</h1>
							<p className="white-color" >Say goodbye to endless weeks of hunting for the perfect home
								design—see it come to life in mere seconds!
							</p>
						</div>
						<div>
						<div className="row card-slider9 ">
							<div className="col-md-6">
								<div className="img-holder">
									<img src="assets/images/Rectangle 37-1.png"/>
									<img src="assets/images/Rectangle 37-2.png" style={{marginLeft: "-4px"}}/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="txt-holder">
									<h2>For You</h2>
									<h3>Endless Home Transformation Concepts</h3>
									<p>Perfect for those seeking a home redesign or decor refresh. AI-powered virtual
										staging offers the simplest and most cost-effective method to visualize any part
										of your home with various styles. Say goodbye to the endless hours of scouring
										numerous design websites. Just snap or upload a photo, and witness the magic as
										Nesmah. AI swiftly transforms it into a hyper-realistic rendering in mere
										seconds! Witness your room's future appearance before making any alterations.
									</p>
								</div>
							</div>
						</div>
						<div className="row card-slider9 flex-container" id="Furnish-Interior">

							<div className="col-md-6 order-1">
								<div className="txt-holder">
									<h2>Virtual Staging</h2>
									<h3>Furnish empty rooms or change furniture of furnished rooms.</h3>
									<p>Transforming empty rooms into inviting spaces has never been easier with virtual
										staging. Imagine the freedom to explore countless furniture styles, all at your
										fingertips, without the hassle of buying, moving, or decorating.
										<br />With virtual staging, you're in control, saving you time, effort,
										and money. Say goodbye to the stress of traditional staging and hello to the
										convenience of digital magic. Our solution empowers you to envision your dream
										space effortlessly. Discover how virtual staging can bring your vision to life,
										and see your empty rooms burst with potential.
									</p>
								</div>
							</div>
							<div className="col-md-6 order-0">
								<div className="img-holder">
									<img src="assets/images/Rectangle 36-1.png"/>
									<img src="assets/images/Rectangle 36-2.png" style={{marginLeft: "-4px"}}/>
								</div>
							</div>
						</div>
						<div className="row card-slider9" id="Redesign-Interior">
							<div className="col-md-6">
								<div className="img-holder">
									<img src="assets/images/Rectangle 38-1.png"/>
									<img src="assets/images/Rectangle 38-2.png" style={{marginLeft: "-4px"}}/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="txt-holder">
									<h2>Room Redesign</h2>
									<h3>Transform entire rooms from floor to ceiling</h3>
									<p>Revamping entire rooms, from walls to floors and windows, becomes a breeze with
										AI-powered redesigning. Picture the ability to reimagine your spaces,
										experimenting with different layouts, colors, and materials, all without the
										hassle of extensive renovations. With AI, you're in the driver's seat, making it
										a cost-effective, efficient, and visually-stunning transformation.
										<br />
										Our solution empowers you to visualize your ideal space effortlessly, enabling
										you to bring your interior dreams to life. Get ready to watch your living spaces
										undergo a breathtaking makeover!
									</p>
								</div>
							</div>
						</div>
						<div className="row card-slider9 flex-container" id="Landscaping">

							<div className="col-md-6 order-1">
								<div className="txt-holder">
									<h2>Landscaping</h2>
									<h3>Elevate your outdoor paradise</h3>
									<p>Envision a garden that perfectly matches your preferences, from the arrangement
										of plants to the design of pathways, all achieved without the laborious work of
										manual landscaping. AI puts you in command, saving you time, money, and the
										physical strain of traditional yard makeovers.
										<br /> Our solution enables you to effortlessly bring your outdoor vision
										to life.
									</p>
								</div>
							</div>
							<div className="col-md-6 order-0">
								<div className="img-holder">
									<img src="assets/images/Rectangle 34.png" style={{width: "100%"}}/>
								</div>
							</div>
						</div>
						<div className="row card-slider9">
							<div className="col-md-6">
								<div className="img-holder">
									<img src="assets/images/Rectangle 39-1.png"/>
									<img src="assets/images/Rectangle 39-2.png" style={{marginLeft: "-4px"}}/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="txt-holder">
									<h2>Generate</h2>
									<h3>Let your imagination fly</h3>
									<p>Prepare for a revolutionary experience in design and decoration. Nesmah empowers
										designers and decorators alike to envision any facet of the home, allowing for
										creative exploration across various styles and designs.
										<br />Our cutting-edge, proprietary design generation technology swiftly
										produces hyper-realistic renderings in mere seconds, saving you valuable time
										and resources.
										<br />Whether you're a seasoned professional designer or embarking on your
										creative journey, NesmahI is your ultimate partner in elevating your design
										visions to new heights.
									</p>
								</div>
							</div>
						</div>
						<div className="row card-slider9 flex-container" id="Building-Exterior">

							<div className="col-md-6 order-1">
								<div className="txt-holder">
									<h2>IMPRESS CLIENTS WITH IDEAS</h2>
									<h3>Architects, Home Builders</h3>
									<p>Architects and builders can also use our interior design software to quickly show
										clients ideas that would have taken them days to draw up in a software. Get
										started on your project faster by helping you reach an agreement with the client
										on the general direction. You can gather ideas, save the ones that clients
										liked, and begin working on your projects based on the feedback you receive.
										Stop clients from changing their minds by giving quick design ideas.</p>
								</div>
							</div>
							<div className="col-md-6 order-0">
								<div className="img-holder">
									<img src="assets/images/Rectangle 35.png" style={{width: "100%"}}/>
								</div>
							</div>
						</div>
						<div className="btn-holder text-center ">
							<a className="btn active text-center marg-b-120" href="#upload-images">Get Started</a>
						</div>
					</div>
				</div>
			</div>
            </div>
    )
}
export default Services;