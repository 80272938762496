import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Sidebar from "./sidebar";

const Layout: React.FunctionComponent<{}> = (props) => {

    return (
            <div className="container-scroller" style={{overflow: 'none'}}>
                  <Header />
                  <div className="container-fluid page-body-wrapper" style={{paddingTop: "0px"}}>
                   <Sidebar/>
                   <div className="main-panel">
                   <Outlet />
                   </div>
                   </div>
            </div>
    )
}

export default Layout