import axios from "axios";

const Url = process.env.REACT_APP_URl;

export const GenerateImage = async (obj: any) => {
    const apiUrl=Url+"/generate_images";
    const response= await axios.post(apiUrl,obj,{
        headers: {
          "Authorization": "Bearer "+localStorage.getItem("token_user")
        }
      })
    return response.data;
   }

   export const GetHistory = async () => {
    const apiUrl=Url+"/history";
    const response= await axios.get(apiUrl,{
        headers: {
          "Authorization": "Bearer "+localStorage.getItem("token_user")
        }
      })
    return response.data;
   }   
   
   export const GetItemsRecommendation = async (obj:any) => {
    const apiUrl=Url+"/items_recommendation";
    const response= await axios.post(apiUrl,obj,{
        headers: {
          "Authorization": "Bearer "+localStorage.getItem("token_user")
        }
      })
    return response.data;
   } 

   export const DownloadEnhancedImage = async (obj:any) => {
    const apiUrl=Url+"/download_enhanced_image";
    const response= await axios.post(apiUrl,obj,{
        headers: {
          "Authorization": "Bearer "+localStorage.getItem("token_user")
        }
      })
    return response.data;
   } 
   