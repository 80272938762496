import React, { useState } from "react";
import { ContactUs } from "../../Services/contact-us";

const ContactAr: React.FunctionComponent<{}> = (props) => {

    const [errorMessage, setErrorMessage] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [errors, setErrors] = useState<any>({ "name": "", "email": "", "message": "" });
    const [formData, setFormData] = useState<any>({ "name": "", "email": "", "message": "" });


    const handleChangeName = (event: any) => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            name: event.target.value,
        }));
    }

    const handleChangeEmail = (event: any) => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            email: event.target.value,
        }));
    }

    const handleChangeMessage = (event: any) => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            message: event.target.value,
        }));
    }

    const handleSend = (event: any) => {
        setErrorMessage("")
		setSuccessMessage("")
        event.preventDefault();
        if (!formData.email) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'يرجى إدخال عنوان البريد الإلكتروني الخاص بك',
            }));
        }
        if (!formData.name) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                name: 'يرجى إدخال اسمك',
            }));
        }
        if (formData.email && !formData.email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'يرجى إدخال عنوان البريد الإلكتروني الخاص بك بصيغة صحيحة',
            }));
        }
        // Validation message 
        if (!formData.message) {

            setErrors((prevFormData: any) => ({
                ...prevFormData,
                message: 'يرجى إدخال رسالتك',
            }));
        } else {
            ContactUs({ "name": formData.name, "email": formData.email, "message": formData.message }).then(res => {
                setSuccessMessage("تم إرسال البريد الإلكتروني بنجاح")
            }).catch((error) => {
                setErrorMessage("يرجى المحاولة مرة أخرى. لم يتم إرسال رسالتك.")
            })
        }
        // setTimeout(() => {
        //     setErrorMessage("")
        //     setSuccessMessage("")
        // }, 3000)
    }

    return (
        <div className="container">

			<div className="row" style={{ display: "flex", justifyContent: "center" }}>
				<div className="col-md-10">
				<div className="contact-sec " dir="auto">
 
                        <h3 className="marg">اتصل بنا</h3>
                        {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
                        <form id="contactform" className="contact-form" onSubmit={handleSend}>
                            <div className="form-group">
                                <div className="col-email-ar marg-form-group">
                                    <input type="email" className="form-control" placeholder="البريد الإلكتروني"
                                        name="contact_email" id="contact_email" value={formData.email} onChange={handleChangeEmail} />
                                    {errors.email && <span className="error" style={{float :"right"}}>{errors.email}</span>}
                                </div>
                                <div className="col marg-form-group">
                                    <input type="text" className="form-control" placeholder="الإ سم"
                                        name="contact_name" id="contact_name" value={formData.name} onChange={handleChangeName} />
                                    {errors.name && <span className="error" style={{float :"right"}}>{errors.name}</span>}
                                </div>
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" placeholder="رسالة" name="contact_message"
                                    id="contact_message" value={formData.message} onChange={handleChangeMessage}></textarea>
                                      {errors.message && <span className="error" style={{float :"right"}}>{errors.message}</span>}
                            </div>
                            <button type="submit" className="btn">إرسال</button>

                        </form>
                    </div>
                </div>
            </div >
        </div >
    )
}
export default ContactAr;