import React, { useState } from "react";

const CommonQuestions: React.FunctionComponent<{}> = (props) => {


    const [isShowQues1, setIsShowQues1] = useState<Boolean>(true);
    const [isShowQues2, setIsShowQues2] = useState<Boolean>(true);
    const [isShowQues3, setIsShowQues3] = useState<Boolean>(true);
    const [isShowQues4, setIsShowQues4] = useState<Boolean>(true);
    const [isShowQues5, setIsShowQues5] = useState<Boolean>(true);
    const [isShowQues6, setIsShowQues6] = useState<Boolean>(true);

    return (
        <div className="service-sec service-common" dir="auto">
            <div className="container">
                <div className="row flex-container">

                    <div className="col-md-8 order-1">
                        <div className="card-common">
                            <div className="question">
                                ما هو ترتيب الغرف الافتراضي؟
                                {isShowQues1 ? <span  className="pointer-left"  onClick={()=>setIsShowQues1(!isShowQues1)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                :<span className="pointer-left" onClick={()=>setIsShowQues1(!isShowQues1)}>
                                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                </svg>
                                </span>}
                            </div>
                            {isShowQues1 ==false &&   <p className="question" style={{ fontWeight: "400", marginTop: "5px", lineHeight: "28px", opacity: "0.7" }}>
                                ترتيب الغرف الافتراضي هو تقنية رقمية تُستخدم لتحسين وتصوير الفضاءات العقارية عن طريق إضافة الأثاث والديكور وعناصر أخرى إلى الصور، مما يخلق تمثيلًا جذابًا وواقعيًا للعقار.</p>
}
                        </div>
                        <div className="card-common">
                            <div className="question">
                                    لماذا يجب أن أختار التدريج الافتراضي بدلاً من التدريج الفعلي؟
                                    {isShowQues2 ? <span  className="pointer-left"  onClick={()=>setIsShowQues2(!isShowQues2)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                :<span className="pointer-left" onClick={()=>setIsShowQues2(!isShowQues2)}>
                                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                </svg>
                                </span>}
                            </div>

                            {isShowQues2 ==false && <p className="question" style={{ fontWeight: "400", marginTop: "5px", lineHeight: "28px", opacity: "0.7" }}>
                                يقدم الترتيب الافتراضي توفيرًا في التكاليف، ومرونة، وسرعة مقارنة بالترتيب الفعلي. يسمح بتعديلات سريعة، وهو أكثر اقتصادية، ويقضي على حاجة إلى الأثاث الفعلي، مما يجعله مثاليًا للتسويق عبر الإنترنت وتسريع عمليات بيع العقار.
                            </p>}


                        </div>

                        <div className="card-common">
                            <div className="question">
                                ما نوع الصور الذي تدعمونه؟
                                {isShowQues3 ? <span  className="pointer-left"  onClick={()=>setIsShowQues3(!isShowQues3)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                :<span className="pointer-left" onClick={()=>setIsShowQues3(!isShowQues3)}>
                                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                </svg>
                                </span>}
                            </div>

                            {isShowQues3 ==false &&    <p className="question" style={{ fontWeight: "400", marginTop: "5px", lineHeight: "28px", opacity: "0.7" }}>
                                يدعم ترتيب الغرف بواسطة الذكاء الاصطناعي لدينا مجموعة متنوعة من صور العقارات، بما في ذلك الغرف الفارغة أو الفضاءات ذات الأثاث الحالي. الصور عالية الجودة، والمضاءة بشكل جيد، مع تفاصيل واضحة للغرفة، تحقق أفضل النتائج.
                            </p>}
                        </div>
                        <div className="card-common">
                            <div className="question">
                                كم يستغرق ترتيب الغرف الافتراضي بواسطة الذكاء الاصطناعي؟

                                {isShowQues4 ? <span  className="pointer-left"  onClick={()=>setIsShowQues4(!isShowQues4)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                :<span className="pointer-left" onClick={()=>setIsShowQues4(!isShowQues4)}>
                                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                </svg>
                                </span>}
                            </div>

                            {isShowQues4 ==false && <p className="question" style={{ fontWeight: "400", marginTop: "5px", lineHeight: "28px", opacity: "0.7" }}>
                                الوقت اللازم لترتيب الغرف بواسطة الذكاء الاصطناعي يعتمد على عوامل مثل تعقيد الصورة والدقة. بشكل عام، يكون العملية سريعة، غالبًا تستغرق أقل من 8 ثوانٍ.
                            </p>}
                        </div>
                        <div className="card-common">
                            <div className="question-gras">
                                ما حجم مكتبة الأثاث لديكم؟
                                {isShowQues5 ? <span  className="pointer-left"  onClick={()=>setIsShowQues5(!isShowQues5)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                :<span className="pointer-left" onClick={()=>setIsShowQues5(!isShowQues5)}>
                                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                </svg>
                                </span>}
                            </div>
                            {isShowQues5==false &&   <p className="question" style={{ fontWeight: "400", marginTop: "5px", lineHeight: "28px", opacity: "0.7" }}>
                                لا يعتمد ترتيب الغرف بواسطة الذكاء الاصطناعي على مكتبة أثاث محددة مسبقًا. بدلاً من ذلك، يحلل كل غرفة على حدة ويولّد أثاثًا استنادًا إلى أسلوب ونوع الغرفة. وهذا يضمن تجربة ترتيب افتراضية فريدة ومصممة خصيصًا لكل غرفة، دون تكرار للأثاث عبر فضول مختلفة.

                            </p>}
                        </div>
                        <div className="card-common">
                            <div className="question">
                                اشتريت خطة ولكن لا أستطيع الوصول إليها. ماذا يجب أن أفعل؟
                                {isShowQues6 ? <span  className="pointer-left"  onClick={()=>setIsShowQues6(!isShowQues6)}>
                                    <svg width="30" height="30" viewBox="0 0 34 34" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z"
                                            fill="#292D32" />
                                        <path
                                            d="M17 26.5625C16.4192 26.5625 15.9375 26.0808 15.9375 25.5V8.5C15.9375 7.91917 16.4192 7.4375 17 7.4375C17.5808 7.4375 18.0625 7.91917 18.0625 8.5V25.5C18.0625 26.0808 17.5808 26.5625 17 26.5625Z"
                                            fill="#292D32" />
                                    </svg>
                                </span>
                                :<span className="pointer-left" onClick={()=>setIsShowQues6(!isShowQues6)}>
                                <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5 18.0625H8.5C7.91917 18.0625 7.4375 17.5808 7.4375 17C7.4375 16.4192 7.91917 15.9375 8.5 15.9375H25.5C26.0808 15.9375 26.5625 16.4192 26.5625 17C26.5625 17.5808 26.0808 18.0625 25.5 18.0625Z" fill="#292D32" />
                                </svg>
                                </span>}
                            </div>

                            {isShowQues6 ==false && <p className="question" style={{ fontWeight: "400", marginTop: "5px", lineHeight: "28px", opacity: "0.7" }}>
                                إذا كنت تواجه مشكلات في الوصول إلى الخطة التي قمت بشرائها، يرجى الاتصال بفريق دعم العملاء لدينا. سيقومون بمساعدتك في حل المشكلة وضمان الوصول إلى خدمات ترتيب الغرف الافتراضي التي قمت بشرائها.
                            </p>}
                        </div>
                    </div>
                    <div className="col-md-4 order-0">
                        <header className=" text-center header">
                            <h2 className="service-common-h2">الأسئلة الشائعة</h2>
                            <h3 className="main-heading2 text-align-start">الأسئلة الشائعة</h3>
                        </header>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CommonQuestions;