import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { useLocation } from 'react-router-dom';
import { isLogged } from '../../Services/Authentication';

const Header: React.FunctionComponent<{}> = () => {
 
    let location = useLocation().pathname.split('/');
    const pathname = location[1];
    const getSidebar = (event: any) => {
        event.preventDefault();
        $("body").toggleClass("nav-active");
    }
    
    return (
        <header id="header">
        <div className="header-area">
            <div className="container">
                <div className="row" style={{ marginRight: "5px" }}>

                    <h3 className="h-title-18">
                        <a style={{textDecorationLine:"underline",color:"#101410", marginRight: "7px"}}
                            className="font-600">يتعلم أكثر</a>
                        ! أطلق العنان للتوفيرات الحصرية: احصل على وصول مبكر إلى تخفيضاتنا المحدودة المدة

                        <svg width="22" height="22" viewBox="0 0 28 28" style={{marginLeft: "10px"}} fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.955 6.07834L19.6 9.36834C19.8217 9.82334 20.4167 10.255 20.9183 10.3483L23.8933 10.8383C25.795 11.1533 26.2383 12.53 24.8733 13.9067L22.5517 16.2283C22.1667 16.6133 21.945 17.3717 22.0733 17.92L22.7383 20.79C23.2633 23.0533 22.05 23.94 20.055 22.75L17.2667 21.0933C16.765 20.79 15.925 20.79 15.4233 21.0933L12.635 22.75C10.64 23.9283 9.42667 23.0533 9.95167 20.79L10.6167 17.92C10.7217 17.36 10.5 16.6017 10.115 16.2167L7.79334 13.895C6.42834 12.53 6.87167 11.1533 8.77334 10.8267L11.7483 10.3367C12.25 10.255 12.845 9.81168 13.0667 9.35667L14.7117 6.06667C15.61 4.29334 17.0567 4.29334 17.955 6.07834Z"
                                fill="#101410" />
                            <path
                                d="M9.33334 6.70833H2.33334C1.85501 6.70833 1.45834 6.31166 1.45834 5.83333C1.45834 5.35499 1.85501 4.95833 2.33334 4.95833H9.33334C9.81168 4.95833 10.2083 5.35499 10.2083 5.83333C10.2083 6.31166 9.81168 6.70833 9.33334 6.70833Z"
                                fill="#101410" />
                            <path
                                d="M5.83334 23.0417H2.33334C1.85501 23.0417 1.45834 22.645 1.45834 22.1667C1.45834 21.6883 1.85501 21.2917 2.33334 21.2917H5.83334C6.31168 21.2917 6.70834 21.6883 6.70834 22.1667C6.70834 22.645 6.31168 23.0417 5.83334 23.0417Z"
                                fill="#101410" />
                            <path
                                d="M3.50001 14.875H2.33334C1.85501 14.875 1.45834 14.4783 1.45834 14C1.45834 13.5217 1.85501 13.125 2.33334 13.125H3.50001C3.97834 13.125 4.37501 13.5217 4.37501 14C4.37501 14.4783 3.97834 14.875 3.50001 14.875Z"
                                fill="#101410" />
                        </svg>

                    </h3>
                </div>
            </div>
        </div>
        <div className="header-holder">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">

                        <nav id="nav">
                            <ul className="list-unstyled">
                                <li>
                                    <a href="/home-en" style={{color: "#2EBB84"}}>EN</a>
                                </li>
                                <li>
                                    <a className="upgrade" href="#pricing">
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.57007 18.57L3.50007 12.5L9.57007 6.43" stroke="#2EBB84"
                                                stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path d="M20.5 12.5L3.67 12.5" stroke="#2EBB84" stroke-width="2"
                                                stroke-miterlimit="10" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg> التطور للاحترافية
                                    </a>
                                </li>

                                <li className={`${pathname == "home-ar" || pathname == "" ? "active" : ""}`}>
                                    <a href="home-ar">بيت</a>
                                </li >
                                <li className={`${pathname == "contact-ar" ? "active" : ""}`}><a href="/contact-ar">اتصال</a></li>
                                <li className={`${pathname == "login-ar" ? "active" : ""}`}>
                                {!isLogged() ? <a href="/login-ar">تسجيل الدخول</a>:
                                     <a href="redesign">
                                        <img src="assets/images/face1.jpg" className="nav-profile" alt="profile" /></a>}
                                    
                                </li>

                            </ul>
                        </nav>
                        <div className="logo">
                            <a href="home-ar">
                                <img src="assets/images/Nesmah Arabe.png" className="img-ar" />
                            </a>
                        </div>
                        <a onClick={getSidebar} className="nav-opener text-center md-round"><svg width="28" height="28"
                                viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M24.5 9.04167H3.5C3.02167 9.04167 2.625 8.645 2.625 8.16667C2.625 7.68834 3.02167 7.29167 3.5 7.29167H24.5C24.9783 7.29167 25.375 7.68834 25.375 8.16667C25.375 8.645 24.9783 9.04167 24.5 9.04167Z"
                                    fill="#292D32" />
                                <path
                                    d="M24.5 14.875H3.5C3.02167 14.875 2.625 14.4783 2.625 14C2.625 13.5217 3.02167 13.125 3.5 13.125H24.5C24.9783 13.125 25.375 13.5217 25.375 14C25.375 14.4783 24.9783 14.875 24.5 14.875Z"
                                    fill="#292D32" />
                                <path
                                    d="M24.5 20.7083H3.5C3.02167 20.7083 2.625 20.3117 2.625 19.8333C2.625 19.355 3.02167 18.9583 3.5 18.9583H24.5C24.9783 18.9583 25.375 19.355 25.375 19.8333C25.375 20.3117 24.9783 20.7083 24.5 20.7083Z"
                                    fill="#292D32" />
                            </svg></a>
                    </div>
                </div>
            </div>
        </div>
    </header>
    )
}

export default Header