import axios from "axios";

const Url = process.env.REACT_APP_URl;


export const ContactUs = async (obj: any) => {
    const apiUrl=Url+"/send_contact";
    const response= await axios.post(apiUrl,obj)
    return response.data;
   }

