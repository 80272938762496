import React from "react";
import Collaborations from "./collaborations";
import DesignsTotal from "./designs-numbers";
import GenerateImages from "./generate-images";
import Services from "./services";
import Pricing from "./pricing";
import CommonQuestions from "./common-questions";
import Marquee from "react-fast-marquee";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

const HomeAr: React.FunctionComponent<{}> = (props) => {


    const FIRST_IMAGE = {
        imageUrl: 'assets/images/86d906664a7cf3dd031c33fb7568b27b.png'
    };
    const SECOND_IMAGE = {
        imageUrl: 'assets/images/dca2e7367f5e98eb6832b53a1b6dbee7.png'
    };

    return (
        <>
            <div className="main--slider slider2" id="home">
                <div className="slide bg-full" style={{ background: "#EAF8F3" }}>
                    <div className="container">
                        <div className="caption caption-marg text-center">
                            <span className="button-title">سهل و سريع</span>
                            <h1>إعادة تصميم أو تأثيث أي غرفة
                                بنقرة واحدة</h1>
                            <p dir="auto">ما عليك سوى تحميل صورتك واختيار أحد أنماط التصميم الخمسين الجذابة. شاهد بينما
                                يقوم
                                الذكاء الاصطناعي بإضفاء الحيوية على منزلك من الداخل أو الخارج أو الحديقة بشكل لم يسبق
                                له مثيل.
                            </p>
                            <div className="btn-holder">
                                <a className="btn active text-center" href="#upload-images">ابدأ الآن</a>
                            </div>
                        </div>
                        <div className="image-container">
                            <div className="image-container">
                                <ReactBeforeSliderComponent
                                    firstImage={FIRST_IMAGE}
                                    secondImage={SECOND_IMAGE}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 2 */}
            <Collaborations />
            {/* section 3 */}
            <DesignsTotal />
            {/* section 4 */}
            <GenerateImages />
            {/* section 5*/}
            <div className="service-sec service3" dir="auto" id="why">
                <div className="container">
                    <div className="row">
                        <header className="col-md-12 text-center header">
                            <h3 className="main-heading2">اختر الخطة التي تناسب احتياجاتك!</h3>
                        </header>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="service text-center bg-green">
                                <div className="img-card">
                                    <div className="img">
                                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M46.76 15.9133L33.32 6.50994C29.6567 3.94327 24.0333 4.08327 20.51 6.81327L8.82001 15.9366C6.48667 17.7566 4.64334 21.4899 4.64334 24.4299V40.5299C4.64334 46.4799 9.47334 51.3333 15.4233 51.3333H40.5767C46.5267 51.3333 51.3567 46.5033 51.3567 40.5533V24.7333C51.3567 21.5833 49.3267 17.7099 46.76 15.9133ZM39.3867 31.2666C39.3867 32.1766 38.6633 32.8999 37.7533 32.8999C36.8433 32.8999 36.12 32.1766 36.12 31.2666V30.8466L29.7733 37.1933C29.4233 37.5433 28.9567 37.7066 28.4667 37.6599C28 37.6133 27.5567 37.3333 27.3 36.9366L24.92 33.3899L19.3667 38.9433C19.04 39.2699 18.6433 39.4099 18.2233 39.4099C17.8033 39.4099 17.3833 39.2466 17.08 38.9433C16.45 38.3133 16.45 37.2866 17.08 36.6333L24.0333 29.6799C24.3833 29.3299 24.85 29.1666 25.34 29.2133C25.83 29.2599 26.2733 29.5166 26.53 29.9366L28.91 33.4833L33.8333 28.5599H33.4133C32.5033 28.5599 31.78 27.8366 31.78 26.9266C31.78 26.0166 32.5033 25.2933 33.4133 25.2933H37.7533C37.9633 25.2933 38.1733 25.3399 38.3833 25.4099C38.78 25.5733 39.1067 25.8999 39.27 26.2966C39.3633 26.5066 39.3867 26.7166 39.3867 26.9266V31.2666Z"
                                                fill="#2EBB84" />
                                        </svg>
                                    </div>
                                </div>
                                <h2 className="">زيادة اهتمام المشتري</h2>
                                <p>التدريج الافتراضي له تأثير إيجابي على 83٪ من المشترين.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="service text-center bg-green">
                                <div className="img-card">
                                    <div className="img">
                                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M41.79 25.0133H34.58V8.21326C34.58 4.29326 32.4567 3.49992 29.8667 6.43992L28 8.56326L12.2034 26.5299C10.0334 28.9799 10.9434 30.9866 14.21 30.9866H21.42V47.7866C21.42 51.7066 23.5434 52.4999 26.1334 49.5599L28 47.4366L43.7967 29.4699C45.9667 27.0199 45.0567 25.0133 41.79 25.0133Z"
                                                fill="#2EBB84" />
                                        </svg>
                                    </div>
                                </div>
                                <h2 className="">بيع بشكل أسرع</h2>
                                <p>تبيع المنازل المُنظمة أسرع بنسبة 73٪ من نظيراتها غير المُنظمة.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="service text-center bg-green">
                                <div className="img-card">
                                    <div className="img ">
                                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M29.75 37.1466H31.2667C32.7833 37.1466 34.0433 35.7933 34.0433 34.1599C34.0433 32.1299 33.32 31.7332 32.13 31.3132L29.7733 30.4966V37.1466H29.75Z"
                                                fill="#2EBB84" />
                                            <path
                                                d="M27.93 4.43339C15.05 4.48006 4.61999 14.9567 4.66666 27.8367C4.71333 40.7167 15.19 51.1467 28.07 51.1001C40.95 51.0534 51.38 40.5767 51.3333 27.6967C51.2867 14.8167 40.81 4.41006 27.93 4.43339ZM33.2733 28.0001C35.0933 28.6301 37.5433 29.9834 37.5433 34.1601C37.5433 37.7534 34.72 40.6467 31.2667 40.6467H29.75V42.0001C29.75 42.9567 28.9567 43.7501 28 43.7501C27.0433 43.7501 26.25 42.9567 26.25 42.0001V40.6467H25.41C21.5833 40.6467 18.48 37.4267 18.48 33.4601C18.48 32.5034 19.2733 31.7101 20.23 31.7101C21.1867 31.7101 21.98 32.5034 21.98 33.4601C21.98 35.4901 23.52 37.1467 25.41 37.1467H26.25V29.2601L22.7267 28.0001C20.9067 27.3701 18.4567 26.0167 18.4567 21.8401C18.4567 18.2467 21.28 15.3534 24.7333 15.3534H26.25V14.0001C26.25 13.0434 27.0433 12.2501 28 12.2501C28.9567 12.2501 29.75 13.0434 29.75 14.0001V15.3534H30.59C34.4167 15.3534 37.52 18.5734 37.52 22.5401C37.52 23.4967 36.7267 24.2901 35.77 24.2901C34.8133 24.2901 34.02 23.4967 34.02 22.5401C34.02 20.5101 32.48 18.8534 30.59 18.8534H29.75V26.7401L33.2733 28.0001Z"
                                                fill="#2EBB84" />
                                            <path
                                                d="M21.98 21.8633C21.98 23.8933 22.7033 24.2899 23.8933 24.7099L26.25 25.5266V18.8533H24.7333C23.2166 18.8533 21.98 20.2066 21.98 21.8633Z"
                                                fill="#2EBB84" />
                                        </svg>
                                    </div>
                                </div>
                                <h2 className="">احصل على عروض أعلى</h2>
                                <p>يتم بيع المنازل التي تحتوي على مراحل افتراضية في المتوسط ​​بنسبة 25٪ أكثر. </p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="service text-center bg-green">
                                <div className="img-card">
                                    <div className="img">
                                        <svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M15.5633 32.6667H9.33329C6.76663 32.6667 4.66663 34.7667 4.66663 37.3334V49.0001C4.66663 50.2834 5.71663 51.3334 6.99996 51.3334H15.5633C16.8466 51.3334 17.8966 50.2834 17.8966 49.0001V35.0001C17.8966 33.7167 16.8466 32.6667 15.5633 32.6667Z"
                                                fill="#2EBB84" />
                                            <path
                                                d="M31.1033 23.3333H24.8733C22.3067 23.3333 20.2067 25.4333 20.2067 27.9999V48.9999C20.2067 50.2833 21.2567 51.3333 22.54 51.3333H33.4367C34.72 51.3333 35.77 50.2833 35.77 48.9999V27.9999C35.77 25.4333 33.6933 23.3333 31.1033 23.3333Z"
                                                fill="#2EBB84" />
                                            <path
                                                d="M46.6666 39.6667H40.4366C39.1533 39.6667 38.1033 40.7167 38.1033 42.0001V49.0001C38.1033 50.2834 39.1533 51.3334 40.4366 51.3334H48.9999C50.2833 51.3334 51.3333 50.2834 51.3333 49.0001V44.3334C51.3333 41.7667 49.2333 39.6667 46.6666 39.6667Z"
                                                fill="#2EBB84" />
                                            <path
                                                d="M35.0233 11.3167C35.7466 10.5934 36.0266 9.73004 35.7933 8.98337C35.5599 8.2367 34.8366 7.70004 33.8099 7.5367L31.5699 7.16337C31.4766 7.16337 31.2666 7.00004 31.2199 6.9067L29.9833 4.43337C29.0499 2.54337 26.9266 2.54337 25.9933 4.43337L24.7566 6.9067C24.7333 7.00004 24.5233 7.16337 24.4299 7.16337L22.1899 7.5367C21.1633 7.70004 20.4633 8.2367 20.2066 8.98337C19.9733 9.73004 20.2533 10.5934 20.9766 11.3167L22.7033 13.0667C22.7966 13.1367 22.8666 13.4167 22.8433 13.51L22.3533 15.6567C21.9799 17.2667 22.5866 17.99 22.9833 18.27C23.3799 18.55 24.2433 18.9234 25.6666 18.0834L27.7666 16.8467C27.8599 16.7767 28.1633 16.7767 28.2566 16.8467L30.3333 18.0834C30.9866 18.48 31.5233 18.5967 31.9433 18.5967C32.4333 18.5967 32.7833 18.41 32.9933 18.27C33.3899 17.99 33.9966 17.2667 33.6233 15.6567L33.1333 13.51C33.1099 13.3934 33.1799 13.1367 33.2733 13.0667L35.0233 11.3167Z"
                                                fill="#2EBB84" />
                                        </svg>
                                    </div>
                                </div>
                                <h2 className="">مسابقة التألق</h2>
                                <p>إقناع المزيد من أصحاب المنازل باستخدام أحدث التقنيات.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 6*/}
            <div className="choose-sec container style2" dir="auto">

                <div className="row flex-container1">

                    <div className="col-md-6 order-11">
                        <ul className="list-unstyled choose-list">
                            <li>
                                <div className="img-card">
                                    <div className="img">
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M32 5.3335C17.28 5.3335 5.33331 17.2802 5.33331 32.0002C5.33331 46.7202 17.28 58.6668 32 58.6668C46.72 58.6668 58.6666 46.7202 58.6666 32.0002C58.6666 17.2802 46.72 5.3335 32 5.3335ZM41.4133 32.0802C41.0133 32.4802 40.5066 32.6668 40 32.6668C39.4933 32.6668 38.9866 32.4802 38.5866 32.0802L34 27.4935V41.3335C34 42.4268 33.0933 43.3335 32 43.3335C30.9066 43.3335 30 42.4268 30 41.3335V27.4935L25.4133 32.0802C24.64 32.8535 23.36 32.8535 22.5866 32.0802C21.8133 31.3068 21.8133 30.0268 22.5866 29.2535L30.5866 21.2535C31.36 20.4802 32.64 20.4802 33.4133 21.2535L41.4133 29.2535C42.1866 30.0268 42.1866 31.3068 41.4133 32.0802Z"
                                                fill="#2EBB84" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="txt-holder">
                                    <h4>الخطوة 1</h4>
                                    <p>قم بتحميل الصورة الداخلية أو الخارجية لمنزلك.</p>
                                </div>

                            </li>
                            <li>
                                <div className="img-card">
                                    <div className="img">
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M25.3334 52.0002V48.0002H12C10.5334 48.0002 9.20002 47.4135 8.24002 46.4268C7.25335 45.4668 6.66669 44.1335 6.66669 42.6668C6.66669 39.9202 8.80002 37.6268 11.4934 37.3602C11.6534 37.3335 11.8134 37.3335 12 37.3335H52C52.1867 37.3335 52.3467 37.3335 52.5067 37.3602C53.7867 37.4668 54.9067 38.0268 55.76 38.9068C56.8534 39.9735 57.44 41.4935 57.3067 43.1468C57.0667 45.9468 54.5334 48.0002 51.7067 48.0002H38.6667V52.0002C38.6667 55.6802 35.68 58.6668 32 58.6668C28.32 58.6668 25.3334 55.6802 25.3334 52.0002Z"
                                                fill="#2EBB84" />
                                            <path
                                                d="M45.84 5.3335H35.3333C34.5867 5.3335 34 5.92016 34 6.66683V10.6668C34 11.7602 33.0933 12.6668 32 12.6668C31.76 12.6668 31.5467 12.6135 31.3333 12.5335C30.56 12.2668 30 11.5202 30 10.6668V6.66683C30 5.92016 29.4133 5.3335 28.6667 5.3335H24.64C23.8933 5.3335 23.3067 5.92016 23.3067 6.66683V18.6668C23.3067 19.7602 22.4 20.6668 21.3067 20.6668C20.2133 20.6668 19.3067 19.7602 19.3067 18.6668V12.6668V6.66683C19.3067 5.94683 18.7733 5.38683 18.0533 5.3335H17.9733C13.3333 5.44016 9.73334 9.46683 10.2133 14.1335L11.2 32.1335C11.28 33.5468 12.4533 34.6668 13.8667 34.6668H50.1333C51.5467 34.6668 52.72 33.5468 52.8 32.1335L53.7867 14.1335C54.2667 9.4135 50.56 5.3335 45.84 5.3335Z"
                                                fill="#2EBB84" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="txt-holder">
                                    <h4>الخطوة 2</h4>
                                    <p>قم بإضفاء الطابع الشخصي على النمط الذي يناسبك.</p>
                                </div>
                            </li>
                            <li>
                                <div className="img-card">
                                    <div className="img">
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M52 20L48.0267 23.9733L40.0267 15.9733L44 12C45.12 10.88 46.56 10.3467 48 10.3467C49.44 10.3467 50.88 10.88 52 12C54.2133 14.2133 54.2133 17.7867 52 20Z"
                                                fill="#2EBB84" />
                                            <path
                                                d="M46.16 25.8667L17.3333 54.6667C15.12 56.88 11.5467 56.88 9.33334 54.6667C7.12001 52.4534 7.12001 48.88 9.33334 46.6667L38.16 17.8667L46.16 25.8667Z"
                                                fill="#2EBB84" />
                                            <path
                                                d="M26.5334 9.33338L27.6267 5.62671C27.7334 5.28004 27.6267 4.90671 27.3867 4.64005C27.1467 4.37338 26.72 4.26671 26.3734 4.37338L22.6667 5.46671L18.96 4.37338C18.6134 4.26671 18.24 4.37338 17.9734 4.61338C17.7067 4.88005 17.6267 5.25338 17.7334 5.60004L18.8 9.33338L17.7067 13.04C17.6 13.3867 17.7067 13.76 17.9467 14.0267C18.2134 14.2934 18.5867 14.3734 18.9334 14.2667L22.6667 13.2L26.3734 14.2934C26.48 14.32 26.56 14.3467 26.6667 14.3467C26.9334 14.3467 27.1734 14.24 27.3867 14.0534C27.6534 13.7867 27.7334 13.4134 27.6267 13.0667L26.5334 9.33338Z"
                                                fill="#2EBB84" />
                                            <path
                                                d="M15.8667 25.3334L16.96 21.6267C17.0667 21.28 16.96 20.9067 16.72 20.64C16.4533 20.3734 16.08 20.2934 15.7333 20.4L12 21.4667L8.29334 20.3734C7.94667 20.2667 7.57334 20.3734 7.30667 20.6134C7.04 20.88 6.96 21.2534 7.06667 21.6L8.13334 25.3334L7.04 29.04C6.93334 29.3867 7.04 29.76 7.28 30.0267C7.54667 30.2934 7.92 30.3734 8.26667 30.2667L11.9733 29.1734L15.68 30.2667C15.76 30.2934 15.8667 30.2934 15.9733 30.2934C16.24 30.2934 16.48 30.1867 16.6933 30C16.96 29.7334 17.04 29.36 16.9333 29.0134L15.8667 25.3334Z"
                                                fill="#2EBB84" />
                                            <path
                                                d="M55.8667 38.6665L56.96 34.9599C57.0667 34.6132 56.96 34.2399 56.72 33.9732C56.4533 33.7065 56.08 33.6265 55.7333 33.7332L52.0267 34.8265L48.32 33.7332C47.9733 33.6265 47.6 33.7332 47.3333 33.9732C47.0667 34.2399 46.9867 34.6132 47.0933 34.9599L48.1867 38.6665L47.0933 42.3732C46.9867 42.7199 47.0933 43.0932 47.3333 43.3599C47.6 43.6265 47.9733 43.7065 48.32 43.5999L52.0267 42.5065L55.7333 43.5999C55.8133 43.6265 55.92 43.6265 56.0267 43.6265C56.2933 43.6265 56.5333 43.5199 56.7467 43.3332C57.0133 43.0665 57.0933 42.6932 56.9867 42.3465L55.8667 38.6665Z"
                                                fill="#2EBB84" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="txt-holder">
                                    <h4>الخطوة 3</h4>
                                    <p>شاهد الذكاء الاصطناعي وهو يغير مساحتك في ثوانٍ قليلة!</p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-5 choose-list order-00">
                        <div className="row">
                            <h3 className="main-heading2">كيفية استخدام <span className="clr">نسمه</span>
                            </h3>
                        </div>
                        <div className="row">
                            <p className="step">دليل خطوة بخطوة</p>
                        </div>
                    </div>
                    <div className=" col-md-1 choose-list display">
                    </div>
                </div>
            </div>
            {/* section 7 */}
            <div className="service-sec ">
                <div className="container">
                    <div className="service-Redesign">

                        <div className="row">
                            <h2 className="redisign-title">تصميم داخلي وخارجي بتقنية الذكاء الاصطناعي</h2>
                            <h3 className="title-sec-7 redisign-h3 mb-4 " dir="auto">
                                أعد تصميم منزلك من الداخل والخارج باستخدام أداة التصميم الداخلي والخارجي المعززة
                                بالذكاء الاصطناعي !</h3>
                        </div>

                        <div className="row">
                            <Marquee pauseOnClick={true}>
                                <div className="col-md-4" style={{ width: "400px" }}>
                                    <img src="assets/images/045d54d3bc47027777c3ae770aae550f.png" className="redesign-img" />
                                    <h3 className="redisign-h3 mar-14">غرفة نوم</h3>
                                </div>
                                <div className="col-md-4" style={{ width: "400px" }}>
                                    <img src="https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/home-improvement/wp-content/uploads/2022/09/modern-loft-bathroom.jpeg" className="redesign-img" />
                                    <h3 className="redisign-h3 mar-14">حمام</h3>
                                </div>
                                <div className="col-md-4" style={{ width: "400px" }}>
                                    <img src="assets/images/a88586aba732401eb4b5c4b08a0875c8.png" className="redesign-img" />
                                    <h3 className="redisign-h3 mar-14">غرفة المعيشة</h3>
                                </div>
                                <div className="col-md-4" style={{ width: "400px" }}>
                                    <img src="https://john-lewis.co.uk/wp-content/uploads/2022/03/210615_JLH-IG_0022-Edit_JW-1024x683.jpg" className="redesign-img" />
                                    <h3 className="redisign-h3 mar-14">مطبخ</h3>
                                </div>
                                <div className="col-md-4" style={{ width: "400px" }}>
                                    <img src="https://media.designcafe.com/wp-content/uploads/2022/08/11150354/terrace-lighting-ideas.jpg" className="redesign-img" />
                                    <h3 className="redisign-h3 mar-14">شرفة</h3>
                                </div>
                                <div className="col-md-4" style={{ width: "400px" }}>
                                    <img src="assets/images/7fd4816c96da4476e6ce67bffbd219ad.png" className="redesign-img" />
                                    <h3 className="redisign-h3 mar-14">حديقة</h3>
                                </div>


                            </Marquee>
                        </div>
                    </div>
                </div>
            </div>
            {/*  section 8 */}
            <div className="work-sec container" dir="auto">
                <div className="row">
                    <div className="col-md-12">
                        <div className="work-slider">
                            <div className="slide flex-container">

                                <div className="txt-holder order-1">
                                    <h4 className="marg-r-80">أعد تصميم منزلك الخاص</h4>
                                    <p className="marg-r-80">اكتشف أسلوب الديكور الذي يناسب تفضيلاتك وقم بتوليد مفاهيم
                                        التصميم للمساحات الداخلية والخارجية لمنزلك.</p>
                                </div>
                                <div className="img-holder order-0">
                                    <img src="assets/images/113765238e72b76b1fdc7db41b92a63a.png" alt="image description"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row marg-top-160">
                    <div className="col-md-12">
                        <div className="work-slider">
                            <div className="slide">
                                <div className="img-holder">
                                    <img src="assets/images/c3bff8a74475b7596cc1ffee06c2fdd5.png" alt="image description"
                                    />
                                </div>
                                <div className="txt-holder">
                                    <h4 className=" marg-l-80">ابهر عملائك</h4>
                                    <p className=" marg-l-80">اترك انطباعًا أوليًا رائعًا لدى العملاء المحتملين من خلال
                                        إظهار مدى قدرة عملك على تحسين منزلهم وتوفير الوقت.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  section 9 */}
            <Services />
            {/*  section 10 */}
            <div className="service-sec service-market" dir="auto">
                <div className="row container">
                    <header className="col-md-12 text-center header">
                        <h2 className="service-market-h2">ميزات أولية في السوق</h2>
                        <h3 className="main-heading2">أداة تصميم المنزل بالذكاء الاصطناعي "كل ما تحتاجه".</h3>
                    </header>
                </div>
                <div className="container padd-50">
                    <div className="row">
                        <div className=" col-md-6">
                            <div className="service">
                                <div className="img-card">
                                    <div className="img">
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M48 15.9997C46.3733 15.9997 44.88 15.0663 44.1333 13.6263L42.2133 9.75967C40.9867 7.33301 37.7867 5.33301 35.0667 5.33301H28.96C26.2133 5.33301 23.0133 7.33301 21.7867 9.75967L19.8667 13.6263C19.12 15.0663 17.6267 15.9997 16 15.9997C10.2133 15.9997 5.62666 20.8797 5.99999 26.6397L7.38666 48.6663C7.70666 54.1597 10.6667 58.6663 18.0267 58.6663H45.9733C53.3333 58.6663 56.2667 54.1597 56.6133 48.6663L58 26.6397C58.3733 20.8797 53.7867 15.9997 48 15.9997ZM28 19.333H36C37.0933 19.333 38 20.2397 38 21.333C38 22.4263 37.0933 23.333 36 23.333H28C26.9067 23.333 26 22.4263 26 21.333C26 20.2397 26.9067 19.333 28 19.333ZM32 48.3197C27.04 48.3197 22.9867 44.293 22.9867 39.3063C22.9867 34.3197 27.0133 30.293 32 30.293C36.9867 30.293 41.0133 34.3197 41.0133 39.3063C41.0133 44.293 36.96 48.3197 32 48.3197Z"
                                                fill="#E1B002" />
                                        </svg>
                                    </div>
                                </div>
                                <h2>توليد الصور الداخلية والخارجية</h2>
                                <p>اكتشف ثروة من الإلهام التصميمي لكل شبر من مسكنك، بما في ذلك المساحات الداخلية
                                    والخارجية.</p>
                            </div>
                        </div>
                        <div className=" col-md-6">
                            <div className="service">
                                <div className="img-card">
                                    <div className="img">
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M55.92 2.66699H48.08C44.6934 2.66699 42.6667 4.69366 42.6667 8.08033V15.9203C42.6667 19.307 44.6934 21.3337 48.08 21.3337H55.92C59.3067 21.3337 61.3334 19.307 61.3334 15.9203V8.08033C61.3334 4.69366 59.3067 2.66699 55.92 2.66699ZM56.5067 11.4937C56.1867 11.8137 55.76 11.9737 55.3334 11.9737C54.9067 11.9737 54.48 11.8137 54.16 11.4937L53.68 11.0137V16.987C53.68 17.9203 52.9334 18.667 52 18.667C51.0667 18.667 50.32 17.9203 50.32 16.987V11.0137L49.84 11.4937C49.2 12.1337 48.1334 12.1337 47.4934 11.4937C46.8534 10.8537 46.8534 9.78699 47.4934 9.14699L50.8267 5.81366C50.96 5.68033 51.1467 5.57366 51.3334 5.49366C51.3867 5.46699 51.44 5.46699 51.4934 5.44033C51.6267 5.38699 51.76 5.36033 51.92 5.36033C51.9734 5.36033 52.0267 5.36033 52.08 5.36033C52.2667 5.36033 52.4267 5.38699 52.6134 5.46699C52.64 5.46699 52.64 5.46699 52.6667 5.46699C52.8534 5.54699 53.0134 5.65366 53.1467 5.78699C53.1734 5.81366 53.1734 5.81366 53.2 5.81366L56.5334 9.14699C57.1734 9.78699 57.1734 10.8537 56.5067 11.4937Z"
                                                fill="#E1B002" />
                                            <path
                                                d="M24 27.6797C27.5052 27.6797 30.3467 24.8382 30.3467 21.333C30.3467 17.8278 27.5052 14.9863 24 14.9863C20.4948 14.9863 17.6533 17.8278 17.6533 21.333C17.6533 24.8382 20.4948 27.6797 24 27.6797Z"
                                                fill="#E1B002" />
                                            <path
                                                d="M55.92 21.333H54.6666V33.6263L54.32 33.333C52.24 31.5463 48.88 31.5463 46.8 33.333L35.7066 42.853C33.6266 44.6397 30.2666 44.6397 28.1866 42.853L27.28 42.1063C25.3866 40.453 22.3733 40.293 20.24 41.733L10.2666 48.4263C9.67998 46.933 9.33331 45.1997 9.33331 43.173V20.8263C9.33331 13.3063 13.3066 9.33301 20.8266 9.33301H42.6666V8.07967C42.6666 7.01301 42.8533 6.10634 43.28 5.33301H20.8266C11.12 5.33301 5.33331 11.1197 5.33331 20.8263V43.173C5.33331 46.0797 5.83998 48.613 6.82665 50.7463C9.11998 55.813 14.0266 58.6663 20.8266 58.6663H43.1733C52.88 58.6663 58.6666 52.8797 58.6666 43.173V20.7197C57.8933 21.1463 56.9866 21.333 55.92 21.333Z"
                                                fill="#E1B002" />
                                        </svg>
                                    </div>
                                </div>
                                <h2>صور عالية الجودة</h2>
                                <p>استمتع بسهولة تنزيل الصور التي تم إنشاؤها بواسطة الذكاء الاصطناعي، مما يضمن احتفاظها
                                    بجودتها المذهلة والواضحة.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className=" col-md-6">
                            <div className="service">
                                <div className="img-card">
                                    <div className="img">
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M47.76 28.5867H39.52V9.38672C39.52 4.90672 37.0933 4.00005 34.1333 7.36005L32 9.78672L13.9467 30.3201C11.4667 33.1201 12.5067 35.4134 16.24 35.4134H24.48V54.6134C24.48 59.0934 26.9067 60.0001 29.8667 56.6401L32 54.2134L50.0533 33.6801C52.5333 30.8801 51.4933 28.5867 47.76 28.5867Z"
                                                fill="#E1B002" />
                                        </svg>
                                    </div>
                                </div>
                                <h2>أفكار الذكاء الاصطناعي غير المحدودة</h2>
                                <p>"أطلق العنان لقوة نسمة لتوليد أفكار لا حدود لها تعتمد على الذكاء الاصطناعي لتحسين
                                    منزلك.</p>
                            </div>
                        </div>
                        <div className=" col-md-6">
                            <div className="service">
                                <div className="img-card">
                                    <div className="img">
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M32 12.4004C19.2533 12.4004 8.88 22.7737 8.88 35.5204C8.88 48.2671 19.2533 58.6671 32 58.6671C44.7467 58.6671 55.12 48.2937 55.12 35.5471C55.12 22.8004 44.7467 12.4004 32 12.4004ZM34 34.6671C34 35.7604 33.0933 36.6671 32 36.6671C30.9067 36.6671 30 35.7604 30 34.6671V21.3337C30 20.2404 30.9067 19.3337 32 19.3337C33.0933 19.3337 34 20.2404 34 21.3337V34.6671Z"
                                                fill="#E1B002" />
                                            <path
                                                d="M39.7067 9.19967H24.2934C23.2267 9.19967 22.3734 8.34634 22.3734 7.27967C22.3734 6.21301 23.2267 5.33301 24.2934 5.33301H39.7067C40.7734 5.33301 41.6267 6.18634 41.6267 7.25301C41.6267 8.31967 40.7734 9.19967 39.7067 9.19967Z"
                                                fill="#E1B002" />
                                        </svg>
                                    </div>
                                </div>
                                <h2>تقديم الصور بسرعة</h2>
                                <p>احصل على أول تصميم لمنزلك تم إنشاؤه بواسطة الذكاء الاصطناعي في 30 ثانية أو أقل وشاهد
                                    مستقبل الإبداع السهل.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  section 11 */}
            {/* <DesignStyles /> */}
            {/*  section 12 */}
            <Pricing />
            {/*  section 13 */}

            {/*  section 14 */}
            <div className="service-sec service-community" dir="auto">
                <div className="row">
                    <header className="col-md-12 text-center header">
                        {/* <h2 className="service-market-h2">تعرف على مجتمعنا</h2> */}
                        <h3 className="main-heading2">استمع من عملائنا السعداء</h3>
                    </header>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="service ">
                                <h2>دانيال أ <span style={{ textAlign: "end" }}><svg width="164" height="28"
                                    viewBox="0 0 164 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.0489 2.92705C13.3483 2.00574 14.6517 2.00574 14.9511 2.92705L16.9187 8.98278C17.0526 9.3948 17.4365 9.67376 17.8697 9.67376H24.2371C25.2058 9.67376 25.6086 10.9134 24.8249 11.4828L19.6736 15.2254C19.3231 15.4801 19.1764 15.9314 19.3103 16.3435L21.2779 22.3992C21.5773 23.3205 20.5228 24.0866 19.7391 23.5172L14.5878 19.7746C14.2373 19.5199 13.7627 19.5199 13.4122 19.7746L8.2609 23.5172C7.47719 24.0866 6.42271 23.3205 6.72206 22.3992L8.68969 16.3435C8.82356 15.9314 8.6769 15.4801 8.32642 15.2254L3.17511 11.4828C2.39139 10.9134 2.79417 9.67376 3.76289 9.67376H10.1303C10.5635 9.67376 10.9474 9.3948 11.0813 8.98278L13.0489 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M47.0489 2.92705C47.3483 2.00574 48.6517 2.00574 48.9511 2.92705L50.9187 8.98278C51.0526 9.3948 51.4365 9.67376 51.8697 9.67376H58.2371C59.2058 9.67376 59.6086 10.9134 58.8249 11.4828L53.6736 15.2254C53.3231 15.4801 53.1764 15.9314 53.3103 16.3435L55.2779 22.3992C55.5773 23.3205 54.5228 24.0866 53.7391 23.5172L48.5878 19.7746C48.2373 19.5199 47.7627 19.5199 47.4122 19.7746L42.2609 23.5172C41.4772 24.0866 40.4227 23.3205 40.7221 22.3992L42.6897 16.3435C42.8236 15.9314 42.6769 15.4801 42.3264 15.2254L37.1751 11.4828C36.3914 10.9134 36.7942 9.67376 37.7629 9.67376H44.1303C44.5635 9.67376 44.9474 9.3948 45.0813 8.98278L47.0489 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M81.0489 2.92705C81.3483 2.00574 82.6517 2.00574 82.9511 2.92705L84.9187 8.98278C85.0526 9.3948 85.4365 9.67376 85.8697 9.67376H92.2371C93.2058 9.67376 93.6086 10.9134 92.8249 11.4828L87.6736 15.2254C87.3231 15.4801 87.1764 15.9314 87.3103 16.3435L89.2779 22.3992C89.5773 23.3205 88.5228 24.0866 87.7391 23.5172L82.5878 19.7746C82.2373 19.5199 81.7627 19.5199 81.4122 19.7746L76.2609 23.5172C75.4772 24.0866 74.4227 23.3205 74.7221 22.3992L76.6897 16.3435C76.8236 15.9314 76.6769 15.4801 76.3264 15.2254L71.1751 11.4828C70.3914 10.9134 70.7942 9.67376 71.7629 9.67376H78.1303C78.5635 9.67376 78.9474 9.3948 79.0813 8.98278L81.0489 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M115.049 2.92705C115.348 2.00574 116.652 2.00574 116.951 2.92705L118.919 8.98278C119.053 9.3948 119.437 9.67376 119.87 9.67376H126.237C127.206 9.67376 127.609 10.9134 126.825 11.4828L121.674 15.2254C121.323 15.4801 121.176 15.9314 121.31 16.3435L123.278 22.3992C123.577 23.3205 122.523 24.0866 121.739 23.5172L116.588 19.7746C116.237 19.5199 115.763 19.5199 115.412 19.7746L110.261 23.5172C109.477 24.0866 108.423 23.3205 108.722 22.3992L110.69 16.3435C110.824 15.9314 110.677 15.4801 110.326 15.2254L105.175 11.4828C104.391 10.9134 104.794 9.67376 105.763 9.67376H112.13C112.563 9.67376 112.947 9.3948 113.081 8.98278L115.049 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M149.049 2.92705C149.348 2.00574 150.652 2.00574 150.951 2.92705L152.919 8.98278C153.053 9.3948 153.437 9.67376 153.87 9.67376H160.237C161.206 9.67376 161.609 10.9134 160.825 11.4828L155.674 15.2254C155.323 15.4801 155.176 15.9314 155.31 16.3435L157.278 22.3992C157.577 23.3205 156.523 24.0866 155.739 23.5172L150.588 19.7746C150.237 19.5199 149.763 19.5199 149.412 19.7746L144.261 23.5172C143.477 24.0866 142.423 23.3205 142.722 22.3992L144.69 16.3435C144.824 15.9314 144.677 15.4801 144.326 15.2254L139.175 11.4828C138.391 10.9134 138.794 9.67376 139.763 9.67376H146.13C146.563 9.67376 146.947 9.3948 147.081 8.98278L149.049 2.92705Z"
                                        fill="#2EBB84" />
                                </svg>
                                </span>
                                </h2>
                                <p>تدريج الذكاء الاصطناعي حول رحلة بيع منزلي! نتيجة سريعة و مذهلة.
                                    تنهال علينا الاعجابات في جولة الجولة الافتراضية. إنه ضروري من أجل تعزيز تجربة بيعك.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="service ">
                                <h2>سمير ج<span style={{ textAlign: "end" }}><svg width="164" height="28"
                                    viewBox="0 0 164 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.0489 2.92705C13.3483 2.00574 14.6517 2.00574 14.9511 2.92705L16.9187 8.98278C17.0526 9.3948 17.4365 9.67376 17.8697 9.67376H24.2371C25.2058 9.67376 25.6086 10.9134 24.8249 11.4828L19.6736 15.2254C19.3231 15.4801 19.1764 15.9314 19.3103 16.3435L21.2779 22.3992C21.5773 23.3205 20.5228 24.0866 19.7391 23.5172L14.5878 19.7746C14.2373 19.5199 13.7627 19.5199 13.4122 19.7746L8.2609 23.5172C7.47719 24.0866 6.42271 23.3205 6.72206 22.3992L8.68969 16.3435C8.82356 15.9314 8.6769 15.4801 8.32642 15.2254L3.17511 11.4828C2.39139 10.9134 2.79417 9.67376 3.76289 9.67376H10.1303C10.5635 9.67376 10.9474 9.3948 11.0813 8.98278L13.0489 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M47.0489 2.92705C47.3483 2.00574 48.6517 2.00574 48.9511 2.92705L50.9187 8.98278C51.0526 9.3948 51.4365 9.67376 51.8697 9.67376H58.2371C59.2058 9.67376 59.6086 10.9134 58.8249 11.4828L53.6736 15.2254C53.3231 15.4801 53.1764 15.9314 53.3103 16.3435L55.2779 22.3992C55.5773 23.3205 54.5228 24.0866 53.7391 23.5172L48.5878 19.7746C48.2373 19.5199 47.7627 19.5199 47.4122 19.7746L42.2609 23.5172C41.4772 24.0866 40.4227 23.3205 40.7221 22.3992L42.6897 16.3435C42.8236 15.9314 42.6769 15.4801 42.3264 15.2254L37.1751 11.4828C36.3914 10.9134 36.7942 9.67376 37.7629 9.67376H44.1303C44.5635 9.67376 44.9474 9.3948 45.0813 8.98278L47.0489 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M81.0489 2.92705C81.3483 2.00574 82.6517 2.00574 82.9511 2.92705L84.9187 8.98278C85.0526 9.3948 85.4365 9.67376 85.8697 9.67376H92.2371C93.2058 9.67376 93.6086 10.9134 92.8249 11.4828L87.6736 15.2254C87.3231 15.4801 87.1764 15.9314 87.3103 16.3435L89.2779 22.3992C89.5773 23.3205 88.5228 24.0866 87.7391 23.5172L82.5878 19.7746C82.2373 19.5199 81.7627 19.5199 81.4122 19.7746L76.2609 23.5172C75.4772 24.0866 74.4227 23.3205 74.7221 22.3992L76.6897 16.3435C76.8236 15.9314 76.6769 15.4801 76.3264 15.2254L71.1751 11.4828C70.3914 10.9134 70.7942 9.67376 71.7629 9.67376H78.1303C78.5635 9.67376 78.9474 9.3948 79.0813 8.98278L81.0489 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M115.049 2.92705C115.348 2.00574 116.652 2.00574 116.951 2.92705L118.919 8.98278C119.053 9.3948 119.437 9.67376 119.87 9.67376H126.237C127.206 9.67376 127.609 10.9134 126.825 11.4828L121.674 15.2254C121.323 15.4801 121.176 15.9314 121.31 16.3435L123.278 22.3992C123.577 23.3205 122.523 24.0866 121.739 23.5172L116.588 19.7746C116.237 19.5199 115.763 19.5199 115.412 19.7746L110.261 23.5172C109.477 24.0866 108.423 23.3205 108.722 22.3992L110.69 16.3435C110.824 15.9314 110.677 15.4801 110.326 15.2254L105.175 11.4828C104.391 10.9134 104.794 9.67376 105.763 9.67376H112.13C112.563 9.67376 112.947 9.3948 113.081 8.98278L115.049 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M149.049 2.92705C149.348 2.00574 150.652 2.00574 150.951 2.92705L152.919 8.98278C153.053 9.3948 153.437 9.67376 153.87 9.67376H160.237C161.206 9.67376 161.609 10.9134 160.825 11.4828L155.674 15.2254C155.323 15.4801 155.176 15.9314 155.31 16.3435L157.278 22.3992C157.577 23.3205 156.523 24.0866 155.739 23.5172L150.588 19.7746C150.237 19.5199 149.763 19.5199 149.412 19.7746L144.261 23.5172C143.477 24.0866 142.423 23.3205 142.722 22.3992L144.69 16.3435C144.824 15.9314 144.677 15.4801 144.326 15.2254L139.175 11.4828C138.391 10.9134 138.794 9.67376 139.763 9.67376H146.13C146.563 9.67376 146.947 9.3948 147.081 8.98278L149.049 2.92705Z"
                                        fill="#2EBB84" />
                                </svg>
                                </span>
                                </h2>
                                <p> كوكيل عقاري، هذه أداة تجميع الذكاء الاصطناعي هي سلاحي السري. سهل الاستعمال و يقدم أنماط متنوعة. و يعزز جاذبية الرؤية. يحب العملاء لمسة الحداثة.  مما يسرع عمليات البيع.</p>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="service ">
                                <h2>خالد ح<span style={{ textAlign: "end" }}><svg width="164" height="28"
                                    viewBox="0 0 164 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.0489 2.92705C13.3483 2.00574 14.6517 2.00574 14.9511 2.92705L16.9187 8.98278C17.0526 9.3948 17.4365 9.67376 17.8697 9.67376H24.2371C25.2058 9.67376 25.6086 10.9134 24.8249 11.4828L19.6736 15.2254C19.3231 15.4801 19.1764 15.9314 19.3103 16.3435L21.2779 22.3992C21.5773 23.3205 20.5228 24.0866 19.7391 23.5172L14.5878 19.7746C14.2373 19.5199 13.7627 19.5199 13.4122 19.7746L8.2609 23.5172C7.47719 24.0866 6.42271 23.3205 6.72206 22.3992L8.68969 16.3435C8.82356 15.9314 8.6769 15.4801 8.32642 15.2254L3.17511 11.4828C2.39139 10.9134 2.79417 9.67376 3.76289 9.67376H10.1303C10.5635 9.67376 10.9474 9.3948 11.0813 8.98278L13.0489 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M47.0489 2.92705C47.3483 2.00574 48.6517 2.00574 48.9511 2.92705L50.9187 8.98278C51.0526 9.3948 51.4365 9.67376 51.8697 9.67376H58.2371C59.2058 9.67376 59.6086 10.9134 58.8249 11.4828L53.6736 15.2254C53.3231 15.4801 53.1764 15.9314 53.3103 16.3435L55.2779 22.3992C55.5773 23.3205 54.5228 24.0866 53.7391 23.5172L48.5878 19.7746C48.2373 19.5199 47.7627 19.5199 47.4122 19.7746L42.2609 23.5172C41.4772 24.0866 40.4227 23.3205 40.7221 22.3992L42.6897 16.3435C42.8236 15.9314 42.6769 15.4801 42.3264 15.2254L37.1751 11.4828C36.3914 10.9134 36.7942 9.67376 37.7629 9.67376H44.1303C44.5635 9.67376 44.9474 9.3948 45.0813 8.98278L47.0489 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M81.0489 2.92705C81.3483 2.00574 82.6517 2.00574 82.9511 2.92705L84.9187 8.98278C85.0526 9.3948 85.4365 9.67376 85.8697 9.67376H92.2371C93.2058 9.67376 93.6086 10.9134 92.8249 11.4828L87.6736 15.2254C87.3231 15.4801 87.1764 15.9314 87.3103 16.3435L89.2779 22.3992C89.5773 23.3205 88.5228 24.0866 87.7391 23.5172L82.5878 19.7746C82.2373 19.5199 81.7627 19.5199 81.4122 19.7746L76.2609 23.5172C75.4772 24.0866 74.4227 23.3205 74.7221 22.3992L76.6897 16.3435C76.8236 15.9314 76.6769 15.4801 76.3264 15.2254L71.1751 11.4828C70.3914 10.9134 70.7942 9.67376 71.7629 9.67376H78.1303C78.5635 9.67376 78.9474 9.3948 79.0813 8.98278L81.0489 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M115.049 2.92705C115.348 2.00574 116.652 2.00574 116.951 2.92705L118.919 8.98278C119.053 9.3948 119.437 9.67376 119.87 9.67376H126.237C127.206 9.67376 127.609 10.9134 126.825 11.4828L121.674 15.2254C121.323 15.4801 121.176 15.9314 121.31 16.3435L123.278 22.3992C123.577 23.3205 122.523 24.0866 121.739 23.5172L116.588 19.7746C116.237 19.5199 115.763 19.5199 115.412 19.7746L110.261 23.5172C109.477 24.0866 108.423 23.3205 108.722 22.3992L110.69 16.3435C110.824 15.9314 110.677 15.4801 110.326 15.2254L105.175 11.4828C104.391 10.9134 104.794 9.67376 105.763 9.67376H112.13C112.563 9.67376 112.947 9.3948 113.081 8.98278L115.049 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M149.049 2.92705C149.348 2.00574 150.652 2.00574 150.951 2.92705L152.919 8.98278C153.053 9.3948 153.437 9.67376 153.87 9.67376H160.237C161.206 9.67376 161.609 10.9134 160.825 11.4828L155.674 15.2254C155.323 15.4801 155.176 15.9314 155.31 16.3435L157.278 22.3992C157.577 23.3205 156.523 24.0866 155.739 23.5172L150.588 19.7746C150.237 19.5199 149.763 19.5199 149.412 19.7746L144.261 23.5172C143.477 24.0866 142.423 23.3205 142.722 22.3992L144.69 16.3435C144.824 15.9314 144.677 15.4801 144.326 15.2254L139.175 11.4828C138.391 10.9134 138.794 9.67376 139.763 9.67376H146.13C146.563 9.67376 146.947 9.3948 147.081 8.98278L149.049 2.92705Z"
                                        fill="#2EBB84" />
                                </svg>
                                </span>
                                </h2>
                                <p>إستخدمت تدريج الذكاء الاصطناعي لحدث. بشكل مذهل بديهي مع أنماط متنوعة. كما لو كان ادي مصمم خاص في يدي.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="service ">
                                <h2>مريم ل<span style={{ textAlign: "end" }}><svg width="164" height="28"
                                    viewBox="0 0 164 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.0489 2.92705C13.3483 2.00574 14.6517 2.00574 14.9511 2.92705L16.9187 8.98278C17.0526 9.3948 17.4365 9.67376 17.8697 9.67376H24.2371C25.2058 9.67376 25.6086 10.9134 24.8249 11.4828L19.6736 15.2254C19.3231 15.4801 19.1764 15.9314 19.3103 16.3435L21.2779 22.3992C21.5773 23.3205 20.5228 24.0866 19.7391 23.5172L14.5878 19.7746C14.2373 19.5199 13.7627 19.5199 13.4122 19.7746L8.2609 23.5172C7.47719 24.0866 6.42271 23.3205 6.72206 22.3992L8.68969 16.3435C8.82356 15.9314 8.6769 15.4801 8.32642 15.2254L3.17511 11.4828C2.39139 10.9134 2.79417 9.67376 3.76289 9.67376H10.1303C10.5635 9.67376 10.9474 9.3948 11.0813 8.98278L13.0489 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M47.0489 2.92705C47.3483 2.00574 48.6517 2.00574 48.9511 2.92705L50.9187 8.98278C51.0526 9.3948 51.4365 9.67376 51.8697 9.67376H58.2371C59.2058 9.67376 59.6086 10.9134 58.8249 11.4828L53.6736 15.2254C53.3231 15.4801 53.1764 15.9314 53.3103 16.3435L55.2779 22.3992C55.5773 23.3205 54.5228 24.0866 53.7391 23.5172L48.5878 19.7746C48.2373 19.5199 47.7627 19.5199 47.4122 19.7746L42.2609 23.5172C41.4772 24.0866 40.4227 23.3205 40.7221 22.3992L42.6897 16.3435C42.8236 15.9314 42.6769 15.4801 42.3264 15.2254L37.1751 11.4828C36.3914 10.9134 36.7942 9.67376 37.7629 9.67376H44.1303C44.5635 9.67376 44.9474 9.3948 45.0813 8.98278L47.0489 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M81.0489 2.92705C81.3483 2.00574 82.6517 2.00574 82.9511 2.92705L84.9187 8.98278C85.0526 9.3948 85.4365 9.67376 85.8697 9.67376H92.2371C93.2058 9.67376 93.6086 10.9134 92.8249 11.4828L87.6736 15.2254C87.3231 15.4801 87.1764 15.9314 87.3103 16.3435L89.2779 22.3992C89.5773 23.3205 88.5228 24.0866 87.7391 23.5172L82.5878 19.7746C82.2373 19.5199 81.7627 19.5199 81.4122 19.7746L76.2609 23.5172C75.4772 24.0866 74.4227 23.3205 74.7221 22.3992L76.6897 16.3435C76.8236 15.9314 76.6769 15.4801 76.3264 15.2254L71.1751 11.4828C70.3914 10.9134 70.7942 9.67376 71.7629 9.67376H78.1303C78.5635 9.67376 78.9474 9.3948 79.0813 8.98278L81.0489 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M115.049 2.92705C115.348 2.00574 116.652 2.00574 116.951 2.92705L118.919 8.98278C119.053 9.3948 119.437 9.67376 119.87 9.67376H126.237C127.206 9.67376 127.609 10.9134 126.825 11.4828L121.674 15.2254C121.323 15.4801 121.176 15.9314 121.31 16.3435L123.278 22.3992C123.577 23.3205 122.523 24.0866 121.739 23.5172L116.588 19.7746C116.237 19.5199 115.763 19.5199 115.412 19.7746L110.261 23.5172C109.477 24.0866 108.423 23.3205 108.722 22.3992L110.69 16.3435C110.824 15.9314 110.677 15.4801 110.326 15.2254L105.175 11.4828C104.391 10.9134 104.794 9.67376 105.763 9.67376H112.13C112.563 9.67376 112.947 9.3948 113.081 8.98278L115.049 2.92705Z"
                                        fill="#2EBB84" />
                                    <path
                                        d="M149.049 2.92705C149.348 2.00574 150.652 2.00574 150.951 2.92705L152.919 8.98278C153.053 9.3948 153.437 9.67376 153.87 9.67376H160.237C161.206 9.67376 161.609 10.9134 160.825 11.4828L155.674 15.2254C155.323 15.4801 155.176 15.9314 155.31 16.3435L157.278 22.3992C157.577 23.3205 156.523 24.0866 155.739 23.5172L150.588 19.7746C150.237 19.5199 149.763 19.5199 149.412 19.7746L144.261 23.5172C143.477 24.0866 142.423 23.3205 142.722 22.3992L144.69 16.3435C144.824 15.9314 144.677 15.4801 144.326 15.2254L139.175 11.4828C138.391 10.9134 138.794 9.67376 139.763 9.67376H146.13C146.563 9.67376 146.947 9.3948 147.081 8.98278L149.049 2.92705Z"
                                        fill="#2EBB84" />
                                </svg>
                                </span>
                                </h2>
                                <p> حول تجميع الذكاء الاصطناعي أفكاري إلى واقع رائع. شخص كل تفصيل بطريقة  فردية. من الأثاث إلى مخططات الألوان. رفيق إبداعي لنتيجة جميلة .</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/*  section 15 */}
            <CommonQuestions />
        </>
    )
}
export default HomeAr;