import React, { useEffect, useState } from "react";
import { DownloadEnhancedImage, GetHistory } from "../../Services/Genertae";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const History: React.FunctionComponent<{}> = (props) => {

    const [history, setHistory] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [isSpinnerDown, setIsSpinnerDown] = useState<boolean>(false);

    useEffect(() => {
        GetHistory().then((data) => {
            console.log(data)
            setLoading(false)
            setHistory(data)
        }).catch((error: any) => {
            console.log("error")
        })
    }, [])

    const handleDownload = async (url: any) => {
		setIsSpinnerDown(true)
		const imageName = 'image.jpg';
		const urlImg = `https://www.api-v1.coralytics.com` + url
		try {
			const response = await fetch(urlImg);
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = imageName;
			a.click();
			setIsSpinnerDown(false)
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.error('Error downloading image:', error);
		}
	};

	const handleDownloadEnhance = (url: any,id:any) => {
		const download = document.getElementById("download-button");
		const image = { "image": "https://www.api-v1.coralytics.com" + url ,"id":id}
		const imageName = 'image-enhanced.jpg';
		if (download) {
			download.style.display = "block";
			setIsSpinnerDown(true)
			DownloadEnhancedImage(image).then(async (resp) => {
				try {
					const response = await fetch(resp.src);
					const blob = await response.blob();
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = imageName;
					a.click();
					setIsSpinnerDown(false)
					window.URL.revokeObjectURL(url);
				} catch (error) {
					console.error('Error downloading image:', error);
				}
			})
		}
	}
    return loading ? (<div className="content-wrapper"> Loading ...</div>) : (
        <div className="content-wrapper">
            {history && history.map((history_obj: any, index: any) => {
                return <div className="mb-3" key={"date_" + index}>
                    <h4 className='title-shop' style={{ marginBottom: "20px" }}>{history_obj.date}</h4>
                    {history_obj.list_images.map((image: any, index_: any) => {
                        return <div className="card-dash mb-3" key={index_}>
                            <div className="card-body-dash">
                                <div className="row ">
                                    <div className="col-md-6 marg-disp">
                                        <img src={image.img_before.src} key={"after_" + index} className="img-his" />
                                        <div className="header-img--block header-img--block-before">Before</div>
                                    </div>
                                    <div className="col-md-6">
										<div className="image-hover">
											<img src={`https://www.api-v1.coralytics.com` + image.img_after.src} key={"before_" + index} className="img-his" id="img-after" />
											<div className="header-img--block header-img--block-after">After</div>
											<div className="dropdown" style={{ position: "static" }}>
												<button className="download-button" id="download-button">
													{isSpinnerDown ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
														<Box sx={{ display: "flex" }}>
															<CircularProgress size={30} sx={{ color: "white" }} />
														</Box></div> : <i className="fa-solid fa-download"></i>}
												</button>
												<div className="dropdown-content">
													<a style={{textAlign:"center"}} onClick={() => handleDownload(image.img_after.src)}>Original</a>
													<a style={{textAlign:"center"}} onClick={() => handleDownloadEnhance(image.img_after.src,image._id)}>
														<Tooltip title={<h1 style={{fontSize:"16px",color:"#FFF", padding:"10px"}}>Selecting this option will use up 2 credits</h1>} >
															<IconButton>
															<i className="fa-solid fa-circle-info"></i>
															</IconButton>
														</Tooltip>High Quality</a>
												</div>
											</div>
										</div>
									</div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            })
            }
            {history && history.length===0 &&
                <div className="">
                    <div className="card-dash mb-3">
                        <div className="card-body-dash padd-20">
                         <h2 className="msg-empty"> No History</h2>
                        </div>
                    </div>
                </div>
            }
        </div>
    )

}
export default History;