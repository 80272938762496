import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountObj } from "../../Models/Account";
import { CreateAccount } from "../../Services/Authentication";
import { Autocomplete, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';

const SignUpAr: React.FunctionComponent<{}> = (props) => {

    const [errorMessage, setErrorMessage] = useState<string>();
    const [errors, setErrors] = useState<Partial<AccountObj>>({});
    const [formData, setFormData] = useState<AccountObj>({});
    const [subscription, setSubscription] = useState<any>([{ value: "Basic", type: "الخطة الأساسية" },{ value: "Standard", type: "الخطة القياسية" },{ value: "Enterprise", type: "خطة المؤسسة" }]);
    const theme = createTheme({
		direction: 'rtl',
	});
	const cacheRtl = createCache({
		key: 'muirtl',
		stylisPlugins: [prefixer, rtlPlugin],
	});
    let navigate = useNavigate();

    const handleChangeName = (event: any) => {
        const nameValue = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            name: nameValue,
        }));
    }
    const handleChangeEmail = (event: any) => {
        const emailValue = event.target.value;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            email: emailValue,
        }));
    }
    const handleChangeSubscription = (event: SelectChangeEvent) => {
        const subscription = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            subscription: subscription,
        }));
    };
    const handleChangePassword = (event: any) => {
        const passwordValue = event.target.value;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            password: passwordValue,
        }));
    }

    const handleCreate = (event: any) => {
        event.preventDefault();
        // Validation email
        if (!formData.email) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'الرجاء إدخال عنوان البريد الإلكتروني الخاص بك',
            }));
        }
        if (!formData.name) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                name: 'الرجاء إدخال اسمك',
            }));
        }
        if (formData.email && !formData.email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'يرجى إدخال بريدك الإلكتروني بالتنسيق الصحيح',
            }));
        }
        if (!formData.subscription) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                subscription: 'الرجاء تحديد الاشتراك',
            }));
        }
        // Validation password 
        if (!formData.password) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                password: 'الرجاء إدخال كلمة السر الخاصة بك',
            }));
        } else {
            CreateAccount(formData).then(res => {
                navigate('/login-ar')
            }).catch((error) => {
                setErrorMessage("فشل في إنشاء حساب")
            })
        }
    }

    return (
        <div className="login-sec">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12" dir="auto">
                        <form className="signup-form text-center" onSubmit={handleCreate}>
                            <h2> تسجيل حساب جديد</h2>
                            {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="إسم" value={formData.name}
                                    onChange={handleChangeName} />
                                {errors.name && <span className="error" style={{float :"right"}}>{errors.name}</span>}
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control" placeholder="البريد الإلكتروني" value={formData.email}
                                    onChange={handleChangeEmail} />
                                {errors.email && <span className="error" style={{float :"right"}}>{errors.email}</span>}
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="كلمة المرور" value={formData.password}
                                    onChange={handleChangePassword} />
                                {errors.password && <span className="error" style={{float :"right"}}>{errors.password}</span>}
                            </div>
                            <div className="form-group">
                            <CacheProvider value={cacheRtl}>
								<ThemeProvider theme={theme}>
                            <FormControl fullWidth sx={{ marginTop: "20px" }}>
									<InputLabel id="demo-simple-select-label" sx={{
										color: "#101410",
										fontSize: "18px",
										fontWeight: "400",
                                        lineHeight: "22px",
										borderRadius: "8px",
										marginTop: '-3px',
										fontFamily: 'Source Sans Pro',
										"&.Mui-focused": {
											color: "#101410",
										},
									}}>اشتراك</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={formData.subscription}
										label="اشتراك"
                                        className="subsc-right"
										onChange={handleChangeSubscription}
										sx={{
											background: "rgba(255, 255, 255, 0.05)",
											padding: "12px 0px 12px",
											width: "100%",
											color: "#101410",
											fontSize: "16px",
											fontWeight: "400",
											borderRadius: "8px",
											fontFamily: 'Source Sans Pro',
											height: "50px",
											textOverflow: "ellipsis",
											fontStyle: "normal",
											lineHeight: "22px",

											"&:hover": {
												borderColor: "#2EBB84"
											},
											'.MuiOutlinedInput-notchedOutline': {
												borderColor: '#2EBB84',
											},
											'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
												borderColor: '#2EBB84',
											},
											'&:hover .MuiOutlinedInput-notchedOutline': {
												borderColor: '#2EBB84',
											},
											"&.Mui-focused": {
												borderColor: "#2EBB84",
											},
										}}
									>
										{subscription.map((obj: any, index: any) => {
											return <MenuItem key={index} className='select-material' sx={{
												display: "block",
												width: "100%",
												fontSize: "16px",
												color: "#101410",
												background: "rgba(255, 255, 255, 0.05)",
												fontWeight: "400",
												fontFamily: 'Source Sans Pro',
												height: "50px",
												textOverflow: "ellipsis",
												padding: "12px 12px",
												fontStyle: "normal",
												"&:focus": {
													backgroundColor: "#2EBB84",
													color: "#FFF"
												},
												"&:hover": {
													backgroundColor: "#2EBB84",
													color: "#FFF"
												},
												lineHeight: "22px",
											}} value={obj.value}>{obj.type} </MenuItem>
										})}
									</Select>
							</FormControl>
                            </ThemeProvider>
							</CacheProvider>
                            {errors.subscription && <span className="error" style={{float :"right"}}>{errors.subscription}</span>}
                            </div>
                            <div style={{ marginTop: "50px" }}>
                                <button type="submit" className="btn text-center text-uppercase">تسجيل حساب</button>
                            </div>
                            <div className="sign-a" >
                                <span>هل لديك حساب ؟<a href="/login-ar" style={{marginRight:"10px"}}>تسجيل الدخول</a></span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SignUpAr;