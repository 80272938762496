import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../Services/Authentication";

const LoginAr: React.FunctionComponent<{}> = (props) => {
    
    const [formData, setFormData] = useState<any>({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState<any>({
        email: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState<string>();
    let navigate = useNavigate();


    const handleChangeEmail = (event: any) => {
        const emailValue = event.target.value;
        setFormData((prevFormData:any) => ({
            ...prevFormData,
            email: emailValue,
        }));
    }

    const handleChangePassword = (event: any) => {
        const passwordValue = event.target.value;
        setFormData((prevFormData:any) => ({
            ...prevFormData,
            password: passwordValue,
        }));
    }

    const handleLogin = (event: any) => {
        event.preventDefault();
        // Validation email
        if (!formData.email) {
            setErrors((prevFormData:any) => ({
                ...prevFormData,
                email: 'يرجى إدخال عنوان البريد الإلكتروني الخاص بك',
            }));
        }
        if (formData.email && !formData.email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
            setErrors((prevFormData:any) => ({
                ...prevFormData,
                email: 'يرجى إدخال عنوان البريد الإلكتروني الخاص بك بصيغة صحيحة',
            }));
        }
        // Validation password 
        if (!formData.password) {
            setErrors((prevFormData:any) => ({
                ...prevFormData,
                password: 'يرجى إدخال كلمة السر الخاصة بك',
            }));
        } else {
            login(formData).then(res => {
                navigate('/redesign')
                window.location.reload()
            }).catch(err => {
                    // setIsError(true);
                    console.log(err.response.data.error)
                    setErrorMessage("البريد الإلكتروني أو كلمة مرور غير صالحة");
                })
                setTimeout(()=>{
                    setErrorMessage("");
                },3000)
        }
    } 

    return (
        <div className="login-sec" id="login">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12" dir="auto">

                        <form className="signup-form text-center" onSubmit={handleLogin}>
                                <h2>تسجيل الدخول إلى <span className="clr">نسمة</span></h2>
                                {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}

                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder="البريد الإلكتروني" value={formData.email}
                                    onChange={handleChangeEmail} />
                                    {errors.email && <span className="error" style={{float :"right"}}>{errors.email}</span>}
                                    </div>
                                    <div className="form-group">
                                    <input type="password" className="form-control" placeholder="كلمة المرور" value={formData.password}
                                    onChange={handleChangePassword} />
                                {errors.password && <span className="error" style={{float :"right"}}>{errors.password}</span>}
                                </div>
                                <div style={{marginTop:"50px"}}>
                                <button type="submit" className="btn text-center text-uppercase">تسجيل الدخول</button>
                                {/* <a href="#">هل نسيت كلمة المرور الخاصة بك؟</a> */}
                               </div>
                                <div className="sign-a">
                                <span>ليس لديك حساب ؟<a href="/sign-up-ar" style={{marginRight: "10px"}}>إنشاء حساب</a></span>
                                </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LoginAr;