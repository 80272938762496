import './App.css';
import PublicRouter from './Routes/PublicRouter';
import React from 'react';


const App: React.FunctionComponent<{}> = (props) =>{
  return (
    <PublicRouter/>
  );
}
export default App;
