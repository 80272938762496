import { Route, Routes } from 'react-router-dom';
import HomeEn from '../Component/comp-en/home-en';
import HomeAr from '../Component/comp-ar/home-ar';
import LayoutEn from '../Component/layout-en/layout-en';
import LayoutAr from '../Component/layout-ar/layout-ar';
import ContactAr from '../Component/comp-ar/contact-ar';
import ContactEn from '../Component/comp-en/contact-en';
import Login from '../Component/comp-en/login';
import LoginAr from '../Component/comp-ar/login-ar';
import SignUp from '../Component/comp-en/sign-up';
import AuthGuard from '../Services/AuthGuard';
import SignUpAr from '../Component/comp-ar/sign-up-ar';
import Layout from '../Component/Dash/layout';
import GenerateImages from '../Component/Dash/generate-images';
import History from '../Component/Dash/history';
import Profile from '../Component/Dash/profile';
import AuthGuardEn from '../Services/AuthGuardEn';
import AuthGuardAr from '../Services/AuthGuardAr';

const PublicRouter: React.FunctionComponent<{}> = (props) => {

    return (
        <>
            <Routes>
                <Route path='/' element={<LayoutEn />}>
                    <Route path="" element={<HomeEn />} />
                    <Route path="home-en" element={<HomeEn />} />
                    <Route path="contact-en" element={<ContactEn/>} />
                    <Route path="login-en" element={<AuthGuardEn><Login/></AuthGuardEn>} />
                    <Route path='sign-up-en' element={<AuthGuardEn><SignUp/></AuthGuardEn>}/>
                </Route>
                <Route path='/' element={<LayoutAr />}>
                    <Route path="home-ar" element={<HomeAr />} />
                    <Route path="contact-ar" element={<ContactAr />} />
                    <Route path="login-ar" element={<AuthGuardAr><LoginAr/></AuthGuardAr>} />
                    <Route path='sign-up-ar' element={<AuthGuardAr><SignUpAr/></AuthGuardAr>}/>
                </Route>
                <Route path='/' element={<Layout/>} >
                    <Route path="history" element={<AuthGuard><History /></AuthGuard>} />
                    <Route path="/redesign" element={<AuthGuard><GenerateImages /></AuthGuard>} />
                    <Route path="profile" element={<AuthGuard><Profile/></AuthGuard>} />
                </Route>
            </Routes>
        </>
    )
}
export default PublicRouter;