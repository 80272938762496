import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../Services/Authentication";



const Login: React.FunctionComponent<{}> = (props) => {

     
    const [formData, setFormData] = useState<any>({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState<any>({
        email: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState<string>();
    let navigate = useNavigate();


    const handleChangeEmail = (event: any) => {
        const emailValue = event.target.value;
        setFormData((prevFormData:any) => ({
            ...prevFormData,
            email: emailValue,
        }));
    }

    const handleChangePassword = (event: any) => {
        const passwordValue = event.target.value;
        setFormData((prevFormData:any) => ({
            ...prevFormData,
            password: passwordValue,
        }));
    }

    const handleLogin = (event: any) => {
        event.preventDefault();
        // Validation email
        if (!formData.email) {
            setErrors((prevFormData:any) => ({
                ...prevFormData,
                email: 'Please enter your email address',
            }));
        }
        if (formData.email && !formData.email.match(/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/)) {
            setErrors((prevFormData:any) => ({
                ...prevFormData,
                email: 'Please enter your email in the correct format',
            }));
        }
        // Validation password 
        if (!formData.password) {
            setErrors((prevFormData:any) => ({
                ...prevFormData,
                password: 'Please enter your password',
            }));
        } else {
            login(formData).then(res => {
                navigate('/redesign')
                window.location.reload()
            }).catch(err => {
                    console.log(err.response.data.error)
                    setErrorMessage(err.response.data.error);
                })
            setTimeout(()=>{
                setErrorMessage("");
            },3000)
        }
    } 

    return (
        <div className="login-sec" id="login">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <form className="signup-form text-center" onSubmit={handleLogin}>
                                <h2>Login to <span className="clr">Nesmah</span></h2>
                                {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder="Email Address" value={formData.email}
                                    onChange={handleChangeEmail} />
                                    {errors.email && <span className="error">{errors.email}</span>}
                                    </div>
                                    <div className="form-group">
                                    <input type="password" className="form-control" placeholder="Password" value={formData.password}
                                    onChange={handleChangePassword} />
                                {errors.password && <span className="error">{errors.password}</span>}
                                </div>
                                <div style={{marginTop:"50px"}}>
                                <button type="submit" className="btn text-center text-uppercase">LOGIN</button>
                                {/* <a href="#">Forgot Password?</a> */}
                                </div>
                                <div className="sign-a">
                                <span>Don't have an account ? <a href="/sign-up-en">SIGN UP</a></span>
                                </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login;