import React, { useEffect, useState } from "react";
import { GetAccount } from "../../Services/Authentication";
import { AccountObj } from "../../Models/Account";



const Profile: React.FunctionComponent<{}> = (props) => {

    const [loading, setLoading] = useState(false);
    const [userValue, setUserValue] =useState<AccountObj>({})

    useEffect(() => {
        GetAccount().then(data=>{
            setUserValue(data)
            setLoading(false)
        }).catch(error=>{
            console.log("error")
        })

    }, [])




    return loading ? (<div className="content-wrapper"> Loading ...</div>) : (
        <div className="content-wrapper">
            <div className="row" style={{ display: "flex", justifyContent: "center" }}>
                <div className="col-md-8">
                    <div className="card-dash mb-3">
                        <div className="card-body-dash padd-profile">
                            <div className="row">
                                <div className="col-md-12 ">
                                <h4 className='title-shop'>Profile</h4>
                                </div>
                            </div>
                            <div className="card-credit mb-4">
                                <div className="row padd-20">
                                    <div className="col-md-12 ">
                                        <h3 className="number-credit">{userValue.creditScore}</h3>
                                        <p>Credits Available</p>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <button type="button" className="btn upgrade" style={{textAlign:"right"}}>Upgrade</button>
                                    </div> */}
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-12">
                                    <label htmlFor="Name" className="form-label">Name</label>
                                    <input type="text" id="Name" className="form-control mb-3" placeholder="Your Name" value={userValue.name} />
                                </div>
                                <div className="col-md-12">
                                    <label htmlFor="Email Address" className="form-label ">Email Address</label>
                                    <input type="email" id="Email Address" className="form-control mb-3" placeholder="Email" value={userValue.email} />
                                </div>
                            
                                <div className="col-md-12">
                                    <label htmlFor="Phone Number" className="form-label ">Subscription</label>
                                    <input type="text" id="Phone Number" className="form-control mb-3" placeholder="Subscription" value={userValue.subscription}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Profile
