import { useLocation, useNavigate } from "react-router-dom";


const Sidebar: React.FunctionComponent<{}>=(props)=>{


    let location = useLocation().pathname.split('/');
    const pathname = location[1];

    return (
      <div className="redisgn">
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="">
      <ul className="navbar-nav mr-auto sidenav"  >
          <li className={[pathname == "redesign" ? "active" : "", "nav-item"].join(" ")} style={{ marginTop: "20px" }}>
              <a className="nav-link" href="/redesign">
                  <span className="menu-title">Redesign</span>
              </a>
          </li>
          <li className={[pathname == "history" ? "active" : "", "nav-item"].join(" ")}>
              <a className="nav-link" href="/history">
                  <span className="menu-title">History</span>
              </a>
          </li>
      </ul>
  </div>
  </nav>
  </div>
      //   <nav className="sidebar sidebar-offcanvas" id="sidebar">
      //   <ul className="nav">
         
      //     <li className={[pathname == "redesign" ? "active" : "", "nav-item"].join(" ")}>
      //       <a className="nav-link" href="/redesign">
      //         <i className="icon-paper menu-icon"></i>
      //         <span className="menu-title">Redesign</span>
      //       </a>
      //     </li>
      //     <li className={[pathname == "history" ? "active" : "", "nav-item"].join(" ")}>
      //       <a className="nav-link" href="/history">
      //         <i className="icon-grid menu-icon"></i>
      //         <span className="menu-title">History</span>
      //       </a>
      //     </li>
      //   </ul>
      // </nav>
        )
}
export default Sidebar