import React from 'react';

const Services: React.FunctionComponent<{}> = (props) => {

    return (
		<div className="slider9  main--slider " id="services" style={{background: "#08261B"}}>
		<div className="container">
			<div className="slide">
				<div className="caption text-center" dir="auto">
					<span className="button-title ">من يمكنه الاستفادة من نسمه؟</span>
					<h1 className="white-color">نسمة هي وسيلة توفير الوقت
						والمال لتلبية احتياجاتك الشخصية والعملية
					</h1>
					<p className="white-color">قل وداعًا لأسابيع لا نهاية لها
						من البحث عن تصميم المنزل المثالي -
						شاهده ينبض بالحياة في ثوانٍ معدودة!
					</p>
				</div>
				<div className="row card-slider9 ">
					<div className="col-md-6">
						<div className="img-holder">
							<img src="assets/images/Rectangle 37-1.png"/>
							<img src="assets/images/Rectangle 37-2.png" style={{marginLeft: "-4px"}}/>
						</div>
					</div>
					<div className="col-md-6" dir="auto">
						<div className="txt-holder">
							<h2>من أجلك</h2>
							<h3>مفاهيم تحويل المنزل التي لا
								نهاية لها</h3>
							<p>مثالي لأولئك الذين يبحثون عن إعادة تصميم المنزل أو تحديث الديكور. يوفر التدريج
								الافتراضي المدعوم بالذكاء الاصطناعي الطريقة الأبسط والأكثر فعالية من حيث التكلفة
								لتصور أي جزء من منزلك بأنماط مختلفة. قل وداعًا لساعات طويلة من البحث في العديد
								من مواقع التصميم. ما عليك سوى التقاط صورة أو تحميلها، وشاهد السحر بينما تقوم
								نسمة بتحويلها بسرعة إلى عرض واقعي للغاية في ثوانٍ معدودة! شاهد المظهر المستقبلي
								لغرفتك قبل إجراء أي تعديلات.
							</p>
						</div>
					</div>
				</div>
				<div className="row card-slider9 flex-container " id="Furnish-Interior">

					<div className="col-md-6 order-1" dir="auto">
						<div className="txt-holder">
							<h2>التدريج الظاهري</h2>
							<h3>تأثيث الغرف الفارغة أو تغيير أثاث الغرف المفروشة</h3>
							<p>لم يكن تحويل الغرف الفارغة إلى مساحات جذابة أسهل من أي وقت مضى مع التدريج
								الافتراضي. تخيل الحرية في استكشاف عدد لا يحصى من أنماط الأثاث، كل ذلك في متناول
								يدك، دون متاعب الشراء أو النقل أو الديكور.
								<br />
								مع التدريج الافتراضي، أنت المتحكم، مما يوفر لك الوقت والجهد والمال.قل وداعا لضغوطات التدريج التقليدي  ومرحبًا براحة السحر الرقمي. يمكّنك حلنا من تصور مساحة أحلامك دون عناء. اكتشف كيف يمكن للعرض التدريجي الافتراضي أن يبث الحياة في رؤيتك، وشاهد غرفك الفارغة مليئة بالإمكانات.
							</p>
						</div>
					</div>
					<div className="col-md-6 order-0">
						<div className="img-holder">
							<img src="assets/images/Rectangle 36-1.png"/>
							<img src="assets/images/Rectangle 36-2.png" style={{marginLeft: "-4px"}}/>
						</div>
					</div>
				</div>
				<div className="row card-slider9" id="Redesign-Interior">
					<div className="col-md-6">
						<div className="img-holder">
							<img src="assets/images/Rectangle 38-1.png"/>
							<img src="assets/images/Rectangle 38-2.png" style={{marginLeft: "-4px"}}/>
						</div>
					</div>
					<div className="col-md-6" dir="auto">
						<div className="txt-holder">
							<h2>إعادة تصميم الغرفة</h2>
							<h3>تحويل غرف بأكملها من الأرض إلى السقف</h3>
							<p>أصبح تجديد الغرف بأكملها، من الجدران إلى الأرضيات والنوافذ، أمرًا سهلاً مع إعادة
								التصميم المدعومة بالذكاء الاصطناعي. تصور القدرة على إعادة تصور المساحات الخاصة
								بك، وتجربة تخطيطات وألوان ومواد مختلفة، كل ذلك دون متاعب التجديدات الشاملة. مع
								الذكاء الاصطناعي، أنت في مقعد السائق، مما يجعل التحول فعالاً من حيث التكلفة،
								وفعالاً، ومذهلًا بصريًا.
								<br />
								يمكّنك حلنا من تصور المساحة المثالية الخاصة بك دون عناء، مما يتيح لك تحقيق
								أحلامك الداخلية. استعد لمشاهدة مساحات المعيشة الخاصة بك تخضع لعملية تجديد مذهلة!
							</p>
						</div>
					</div>
				</div>
				<div className="row card-slider9 flex-container" id="Landscaping">

					<div className="col-md-6 order-1" dir="auto">
						<div className="txt-holder">
							<h2>المناظر الطبيعية</h2>
							<h3>ارفع مستوى جنتك الخارجية</h3>
							<p>تصور حديقة تتوافق تمامًا مع تفضيلاتك، بدءًا من ترتيب النباتات وحتى تصميم الممرات، ويتم تحقيق كل ذلك دون العمل الشاق في تنسيق الحدائق يدويا. يضعك الذكاء الاصطناعي في موقع القيادة، مما يوفر لك الوقت والمال والجهد البدني الناتج عن عمليات تجديد الفناء التقليدية. 
								 <br />
								يمكّنك الحل الذي نقدمه من إضفاء الحيوية على رؤيتك الخارجية دون عناء.
							</p>
						</div>
					</div>
					<div className="col-md-6 order-0">
						<div className="img-holder">
							<img src="assets/images/Rectangle 34.png" style={{width: "100%"}}/>
						</div>
					</div>
				</div>
				<div className="row card-slider9">
					<div className="col-md-6">
						<div className="img-holder">
							<img src="assets/images/Rectangle 39-1.png"/>
							<img src="assets/images/Rectangle 39-2.png" style={{marginLeft: "-4px"}}/>
						</div>
					</div>
					<div className="col-md-6" dir="auto">
						<div className="txt-holder">
							<h2>التوليد</h2>
							<h3>دع خيالك يحلق</h3>
							<p>الاستعداد لتجربة ثورية في التصميم والديكور. تعمل نسمة على تمكين المصممين ومصممي
								الديكور على حد سواء من تصور أي جانب من جوانب المنزل، مما يسمح بالاستكشاف
								الإبداعي عبر مختلف الأنماط والتصميمات.
								<br />
								تنتج تقنية إنشاء التصميم المتطورة والمملوكة لنا بسرعة عروضًا واقعية للغاية في
								ثوانٍ معدودة، مما يوفر لك الوقت والموارد الثمينة.
								<br />
								سواء كنت مصممًا محترفًا متمرسًا أو تشرع في رحلتك الإبداعية، فإن نسمة هي شريكك
								الأمثل في الارتقاء برؤيتك التصميمية إلى آفاق جديدة.
							</p>
						</div>
					</div>
				</div>
				<div className="row card-slider9 flex-container" id="Building-Exterior">

					<div className="col-md-6 order-1" dir="auto">
						<div className="txt-holder">
							<h2>إنطلق في إبهار العملاء بأفكارك</h2>
							<h3>المهندسين المعماريين، بناة المنازل</h3>
							<p>يمكن للمهندسين المعماريين والبنائين أيضًا استخدام برامج التصميم الداخلي الخاصة
								بنا لعرض أفكار العملاء بسرعة والتي كانت ستستغرق أيامًا لوضعها في البرنامج. ابدأ
								مشروعك بشكل أسرع من خلال مساعدتك في التوصل إلى اتفاق مع العميل بشأن الاتجاه
								العام. يمكنك جمع الأفكار وحفظ الأفكار التي أعجبت العملاء وبدء العمل على مشاريعك
								بناءً على التعليقات التي تتلقاها. منع العملاء من تغيير آرائهم من خلال تقديم
								أفكار تصميم سريعة.</p>
						</div>
					</div>
					<div className="col-md-6 order-0">
						<div className="img-holder">
							<img src="assets/images/Rectangle 35.png" style={{width: "100%"}}/>
						</div>
					</div>
				</div>
				<div className="btn-holder text-center ">
					<a className="btn active text-center marg-b-120" href="#upload-images"> إبدأ الآن</a>
				</div>
			</div>
		</div>
	</div>
    )
}
export default Services;